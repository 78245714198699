import uuid from 'react-uuid';

export const ourPolicyAnchor = {
  shippingAndFreightPolicy: 'shipping-and-freight-policy',
  cancellations: 'cancellations',
  generalReturns: 'general-returns',
};

export const TandC = {
  title: 'Terminos y Condiciones',
  descriptions: [
    {
      type: 'paragraph',
      text: 'Por favor lea cuidadosamente los siguientes términos y condiciones antes de utilizar este sitio web.',
      bold: true,
    },
    {
      type: 'paragraph',
      text: 'Al visitar nuestro sitio y/o comprar algo de nosotros, usted acepta los siguientes términos y condiciones, incluidos todos los términos y condiciones adicionales y las políticas a las que se hace referencia en este documento y/o están disponibles a través de hipervínculos. Estos Términos de servicio se aplican a todos los usuarios del sitio, incluida su limitación a los usuarios que son navegadores, proveedores, clientes, comerciantes y/o contribuyentes de contenido. Si no está de acuerdo con estos términos y condiciones, no use este sitio.',
      bold: false,
    },
  ],
  sections: [
    {
      id: uuid(),
      title: 'Términos y Condiciones Generales de Venta',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Precios',
          bold: true,
        },
        {
          type: 'paragraph',
          text: 'Los precios y la información que se muestran en este portal están sujetos a cambios sin previo aviso. A menos que se indique lo contrario, todos los precios están en dólares estadounidenses USD.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'Venta',
          bold: true,
        },
        {
          type: 'paragraph',
          text: 'Nos reservamos el derecho, por cualquier motivo, a nuestro exclusivo criterio, de modificar, finalizar, cambiar, suspender o interrumpir cualquier aspecto de nuestro sitio (o cualquier parte del contenido), incluidos, entre otros, el contenido, las características o las horas de disponibilidad e imponer límites a ciertas características de nuestro sitio o restringir su acceso a parte o todo nuestro sitio en cualquier momento sin previo aviso. La información y los materiales contenidos en este portal son precisos según nuestro leal saber y entender; sin embargo, puede contener información técnica inexacta, errores tipográficos y/o inconsistencias. Tsindustrial.com no se hace responsable de los errores tipográficos.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'Aceptación',
          bold: true,
        },
        {
          type: 'paragraph',
          text: 'Todos los pedidos están sujetos a la aprobación de TSIndustrial. Los siguientes términos y condiciones se aplican a todos los pedidos y están sujetos a cambios sin previo aviso. TSIndustrial se reserva el derecho de cancelar pedidos aceptados si hay circunstancias fuera de nuestro control, incluyendo (incendios, inundaciones, huelgas, disputas laborales, accidentes, sabotaje, terrorismo, guerras, disturbios, retrasos del transportista o falta de capacidad para enviar, restricciones impuestas por la ley, escasez de materiales y motivos similares) y otras causas que imposibiliten la entrega del pedido.',
          bold: false,
        },
      ],
    },
    {
      id: uuid(),
      title: 'Términos y Condiciones de Uso',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Las marcas comerciales que aparecen en el sitio pueden ser propiedad y/o estar registradas por terceros distintos de tsindustrial.com, como se indica mediante atribución expresa o la clara implicación del contexto. Ninguna representación contenida en el sitio debe interpretarse como una autorización para la reproducción, retransmisión u otro uso de las marcas comerciales de tsindustrial.com o de otras partes. La información de esta página solo se puede descargar, copiar o reproducir con el fin de realizar pedidos u obtener información sobre los artículos vendidos por tsindustrial.com.',
          bold: false,
        },
      ],
    },
    {
      id: uuid(),
      title: 'Devoluciones y Cancelaciones',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Cancelaciones',
          bold: true,
        },
        {
          type: 'paragraph',
          text: 'Los pedidos no se pueden cancelar una vez procesados.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'Devoluciones Generales',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'Todas las devoluciones deberán solicitarse en un plazo máximo de 30 días desde la fecha de la factura. SÓLO se aceptarán devoluciones de mercancía con el número RA (Autorización-Devolución). Los pedidos de productos especiales como Pinturas, Lubricantes, Tanques de GLP, Productos Químicos y Extintores no son retornables ni cancelables. Las piezas deben devolverse a TSIndustrial con el envío pagado por el remitente.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'Todas las devoluciones (excepto aquellas por producto defectuoso o pieza incorrecta) estarán sujetas a una tarifa de reposición del 25% basada en el valor del producto.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'Los productos devueltos por defectos serán inspeccionados y probados. Cualquier mercancía verificada como defectuosa, a exclusivo criterio de TSIndustrial, será reparada o reemplazada sin cargo o se emitirá una nota de crédito por el valor, incluido el flete. Las piezas devueltas como defectuosas una vez verificadas estar en funcionamiento estará sujeto a una tarifa de reposición del 25 %.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'No se emitirá crédito por mercancías dañadas por materiales corrosivos, abuso, alteración o mal uso.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: "Antes de devolver cualquier mercancía a TSIndustrial, su representante de ventas, departamento de atención al cliente o por correo deberá generar un número de Autorización de Devolución 'Número de Autorización'. Su solicitud debe incluir:",
          bold: false,
        },
        {
          type: 'list',
          list: [
            {
              type: 'paragraph',
              text: 'Número de pieza y descripción',
              bold: false,
            },
            {
              type: 'paragraph',
              text: 'Motivo de la devolución',
              bold: false,
            },
            {
              type: 'paragraph',
              text: 'Número de orden',
              bold: false,
            },
            {
              type: 'paragraph',
              text: 'Número de pedido del cliente',
              bold: false,
            },
          ],
        },
      ],
    },
  ],
};

export const TandCEn = {
  title: 'Terms and Conditions',
  descriptions: [
    {
      type: 'paragraph',
      text: 'Please read the following terms and conditions carefully before using this website.',
      bold: true,
    },
    {
      type: 'paragraph',
      text: 'By visiting our site and/or purchasing something from us, you agree to the following terms and conditions, including all additional terms and conditions and policies referenced herein and/or available via hyperlinks. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content. If you do not agree to these terms and conditions, do not use this site.',
      bold: false,
    },
  ],
  sections: [
    {
      id: 'uuid()',
      title: 'General Sales Terms and Conditions',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Prices',
          bold: true,
        },
        {
          type: 'paragraph',
          text: 'Prices and information displayed on this portal are subject to change without prior notice. Unless otherwise indicated, all prices are in US dollars USD.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'Sale',
          bold: true,
        },
        {
          type: 'paragraph',
          text: 'We reserve the right, for any reason, at our sole discretion, to modify, terminate, change, suspend or discontinue any aspect of our site (or any part of the content), including but not limited to content, features or availability hours and impose limits on certain features of our site or restrict your access to part or all of our site at any time without prior notice. The information and materials contained on this portal are accurate to the best of our knowledge and understanding; however, it may contain inaccurate technical information, typographical errors, and/or inconsistencies. Tsindustrial.com is not responsible for typographical errors.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'Acceptance',
          bold: true,
        },
        {
          type: 'paragraph',
          text: 'All orders are subject to approval by TSIndustrial. The following terms and conditions apply to all orders and are subject to change without prior notice. TSIndustrial reserves the right to cancel accepted orders if there are circumstances beyond our control, including (fires, floods, strikes, labor disputes, accidents, sabotage, terrorism, wars, riots, carrier delays or inability to ship, restrictions imposed by law, material shortages, and similar reasons) and other causes that make it impossible to deliver the order.',
          bold: false,
        },
      ],
    },
    {
      id: 'uuid()',
      title: 'Terms and Conditions of Use',
      descriptions: [
        {
          type: 'paragraph',
          text: "Trademarks appearing on the site may be owned and/or registered by third parties other than tsindustrial.com, as indicated by express attribution or clear implication from the context. No representation contained on the site should be construed as authorization for the reproduction, retransmission or other use of tsindustrial.com's trademarks or those of other parties. The information on this page may only be downloaded, copied or reproduced for the purpose of placing orders or obtaining information about items sold by tsindustrial.com.",
          bold: false,
        },
      ],
    },
    {
      id: 'uuid()',
      title: 'Returns and Cancellations',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Cancellations',
          bold: true,
        },
        {
          type: 'paragraph',
          text: 'Orders cannot be canceled after they have been processed.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'General Returns',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'All returns must be requested within a maximum of 30 days from the invoice date. ONLY returns of merchandise with the RA (Return-Authorization) number will be accepted. Orders for special products such as Paints, Lubricants, LPG Tanks, Chemicals, and Fire Extinguishers are not returnable or cancelable. Parts must be returned to TSIndustrial with shipping paid by the sender.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'All returns (except those for defective product or wrong part) will be subject to a 25% restocking fee based on the product value.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: "Products returned for defects will be inspected and tested. Any merchandise verified as defective, at TSIndustrial's sole discretion, will be repaired or replaced at no charge or a credit note will be issued for the value including freight. Parts returned as defective once verified to be in working order will be subject to a 25% restocking fee.",
          bold: false,
        },
        {
          type: 'paragraph',
          text: 'No credit will be issued for merchandise damaged by corrosive materials, abuse, alteration or misuse.',
          bold: false,
        },
        {
          type: 'paragraph',
          text: "Before returning any merchandise to TSIndustrial, your sales representative, customer service department or by mail must generate a Return Authorization number 'Authorization Number'. Your request must include:",
          bold: false,
        },
        {
          type: 'list',
          list: [
            {
              type: 'paragraph',
              text: 'Part number and description',
              bold: false,
            },
            {
              type: 'paragraph',
              text: 'Reason for return',
              bold: false,
            },
            {
              type: 'paragraph',
              text: 'Invoice number',
              bold: false,
            },
            {
              type: 'paragraph',
              text: 'Customer order number',
              bold: false,
            },
          ],
        },
      ],
    },
  ],
};

export const privacy = {
  id: uuid(),
  title: 'Políticas de privacidad',
  descriptions: [
    {
      bold: false,
      type: 'paragraph',
      text: 'TS INDUSTRIAL se esfuerza por proteger su privacidad en línea mientras le brinda la experiencia web más útil y agradable posible. TS INDUSTRIAL se compromete a proteger su privacidad y desarrollar tecnología que le brinde la experiencia en línea más poderosa y segura. Esta declaración de privacidad se aplica al sitio web www.tsindustrial.com y cubre la recopilación y el uso de datos.',
    },
    {
      bold: false,
      type: 'paragraph',
      text: 'Al usar este sitio web, usted acepta las prácticas de datos descritas en esta declaración. Los usuarios pueden acceder a muchas funciones de nuestro sitio de forma anónima; sin embargo, es posible que se le solicite que proporcione información personal cuando participe en concursos, encuestas o proporcione comentarios o cuando utilice otras áreas interactivas de nuestro sitio. El uso de nuestro sitio implica su comprensión y aceptación de los términos de esta política de privacidad.',
    },
  ],
  sections: [
    {
      id: uuid(),
      title: 'Cómo recopilamos y usamos la información personal',
      descriptions: [
        {
          bold: false,
          type: 'paragraph',
          text: 'Recopilamos información de nuestros usuarios en varios puntos diferentes de nuestro sitio. En general, cada usuario tiene acceso a toda la información y funciones disponibles en nuestro sitio. Sin embargo, a los usuarios que elijan ser informados sobre la disponibilidad de productos se les pedirá que proporcionen información específica del usuario.',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Utilizaremos los datos personales que recabamos de usted para las siguientes finalidades que son necesarias para el servicio que solicita:',
        },
        {
          type: 'list',
          list: [
            {
              type: 'paragraph',
              text: 'Responder a las solicitudes de información sobre productos.',
            },
            {
              type: 'paragraph',
              text: 'Informar sobre actualizaciones de nuestros productos.',
            },
            {
              type: 'paragraph',
              text: 'Conocer el rendimiento de nuestro servicio.',
            },
            {
              type: 'paragraph',
              text: 'Para procesar los pagos, podemos utilizar la información que los usuarios proporcionan sobre sí mismos al realizar un pedido solo para brindar servicio a ese pedido.',
            },
            {
              type: 'paragraph',
              text: 'Para enviar correos electrónicos periódicos, podemos usar la dirección de correo electrónico para enviar información del usuario y actualizaciones relacionadas con su pedido. También podrá ser utilizado para responder a sus consultas, preguntas y/u otras solicitudes.',
            },
          ],
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Adicionalmente, utilizaremos su información personal para las siguientes finalidades que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle un mejor servicio. Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales: nombre completo, correo electrónico, fecha de nacimiento, ciudad de origen y número de teléfono.',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'También existe información sobre el hardware y software de su ordenador o dispositivo personal, que TS INDUSTRIAL recopila automáticamente. Esta información puede incluir: su dirección IP, tipo de navegador de Internet utilizado, nombres de dominio, tiempos de acceso y direcciones de sitios web que lo remiten. Esta información es utilizada por TS INDUSTRIAL para la operación del servicio, para mantener la calidad del servicio prestado y para brindar estadísticas generales acerca del uso del sitio www.tsindustrial.com.',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'TS INDUSTRIAL no tiene que vender, alquilar o ceder sus listas de clientes a terceros.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Registro al sitio',
      descriptions: [
        {
          bold: false,
          type: 'paragraph',
          text: 'A los usuarios que elijan registrarse en nuestros sitios se les pedirá que proporcionen información de contacto (como nombre y dirección de correo electrónico). Usamos esta información, por ejemplo, para comunicarnos con el usuario sobre los servicios en nuestros sitios por los que ha expresado interés. Es generalmente es opcional que el usuario proporcione información demográfica (como el nivel de ingresos y el género) e identificadores únicos (como el nombre de usuario y la contraseña), pero se recomienda para que podamos brindar una experiencia más personalizada en nuestros sitios.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Cómo protegemos su información',
      descriptions: [
        {
          bold: false,
          type: 'paragraph',
          text: 'Adoptamos prácticas adecuadas de recopilación, almacenamiento y procesamiento de datos y medidas de seguridad para proteger su información personal del acceso, uso o divulgación no autorizados. TS INDUSTRIAL mantiene la información de identificación personal que usted ha proporcionado, en servidores ubicados en un entorno controlado y seguro, protegido del acceso, uso o divulgación no autorizados.',
        },
      ],
    },
  ],
};

export const privacyEn = {
  id: 'uuid()',
  title: 'Privacy Policies',
  descriptions: [
    {
      bold: false,
      type: 'paragraph',
      text: 'TS INDUSTRIAL strives to protect your online privacy while providing you with the most useful and enjoyable web experience possible. TS INDUSTRIAL is committed to protecting your privacy and developing technology that gives you the most powerful and safe online experience. This privacy statement applies to the website www.tsindustrial.com and covers data collection and usage.',
    },
    {
      bold: false,
      type: 'paragraph',
      text: 'By using this website, you agree to the data practices described in this statement. Users may access many features of our site anonymously; however, you may be asked to provide personal information when participating in contests, surveys, or providing feedback, or when using other interactive areas of our site. Your use of our site implies your understanding and acceptance of the terms of this privacy policy.',
    },
  ],
  sections: [
    {
      id: 'uuid()',
      title: 'How We Collect and Use Personal Information',
      descriptions: [
        {
          bold: false,
          type: 'paragraph',
          text: 'We collect information from our users at various different points on our site. In general, every user has access to all information and features available on our site. However, users who choose to be informed about product availability will be asked to provide specific user information.',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'We will use the personal data we collect from you for the following purposes which are necessary for the service you request:',
        },
        {
          type: 'list',
          list: [
            {
              type: 'paragraph',
              text: 'Responding to inquiries about products.',
            },
            {
              type: 'paragraph',
              text: 'Informing about updates to our products.',
            },
            {
              type: 'paragraph',
              text: 'Understanding the performance of our service.',
            },
            {
              type: 'paragraph',
              text: 'To process payments, we may use the information users provide about themselves when placing an order only to provide service for that order.',
            },
            {
              type: 'paragraph',
              text: 'For sending periodic emails, we may use the email address to send user information and updates related to their order. It may also be used to respond to inquiries, questions, and/or other requests.',
            },
          ],
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Additionally, we will use your personal information for the following purposes that are not necessary for the requested service, but which allow us to provide you with better service. To carry out the purposes described in this privacy notice, we will use the following personal data: full name, email address, date of birth, city of origin, and phone number.',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'There is also information about your computer or personal device hardware and software that TS INDUSTRIAL automatically collects. This information may include: your IP address, type of internet browser used, domain names, access times, and addresses of referring websites. This information is used by TS INDUSTRIAL for the operation of the service, to maintain service quality, and to provide general statistics about the use of the site www.tsindustrial.com.',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'TS INDUSTRIAL does not have to sell, rent, or lease its customer lists to third parties.',
        },
      ],
    },
    {
      id: 'uuid()',
      title: 'Site Registration',
      descriptions: [
        {
          bold: false,
          type: 'paragraph',
          text: 'Users who choose to register on our sites will be asked to provide contact information (such as name and email address). We use this information, for example, to communicate with the user about services on our sites for which they have expressed interest. It is generally optional for the user to provide demographic information (such as income level and gender) and unique identifiers (such as username and password), but it is recommended so that we can provide a more personalized experience on our sites.',
        },
      ],
    },
    {
      id: 'uuid()',
      title: 'How We Protect Your Information',
      descriptions: [
        {
          bold: false,
          type: 'paragraph',
          text: 'We adopt appropriate data collection, storage, and processing practices and security measures to protect your personal information from unauthorized access, use, or disclosure. TS INDUSTRIAL maintains the personally identifiable information you have provided on servers located in a controlled, secure environment, protected from unauthorized access, use, or disclosure.',
        },
      ],
    },
  ],
};

export const helpAndSupport = {
  id: uuid(),
  title: 'Ayuda',
  descriptions: [
    {
      bold: false,
      type: 'paragraph',
      text: 'Bienvenido a nuestra página de Ayuda, el centro integral de asistencia para todos nuestros valiosos clientes.',
    },
    {
      bold: false,
      type: 'paragraph',
      text: 'Aquí encontrarás recursos y herramientas diseñadas para responder a todas tus preguntas.',
    },
  ],
  sections: [
    {
      id: uuid(),
      title: 'Servicio de asistencia',
      descriptions: [
        {
          bold: false,
          type: 'paragraph',
          text: 'Para obtener más información o ayuda sobre nuestro sitio de comercio electrónico® o TSIPARTS, contáctenos en:',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'T S TOTAL SOLUTION LLC',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Headquarters Nogales, Arizona, USA. 940 N Perkins Ave. Nogales, AZ 85621. USA',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Tel: +1 (520)540-6906',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Correo: soporte@tsiparts.net',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Horas de atención:  Oficina: 8:30 a.m. - 5:30 p.m. (Hora del Pacífico) Lunes a Viernes',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Pago',
      descriptions: [
        {
          bold: true,
          type: 'accordion',
          text: '¿Cuáles son los métodos de pago aceptados?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Las tarjetas son una forma común para que los consumidores y las empresas paguen en línea o en persona. Admitimos varias marcas de tarjetas, desde grandes redes globales como Visa y Mastercard hasta redes locales como Cartes Bancaires en Francia o Interac en Canadá. Aceptamos una diversidad de marcas de tarjetas sin configuraciones adicionales, que incluyen:',
            },
            {
              bold: false,
              type: 'list',
              list: [
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'American Express',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'China UnionPay (CUP)',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'Discover & Diners Club',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'eftpos Australia',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'Japan Credit Bureau (JCB)',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'Mastercard',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'Visa',
                },
              ],
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: '¿Es seguro realizar pagos en su tienda en línea?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Nuestro sitio tiene un certificado SSL que ofrece el cifrado más sólido y funciones de valor agregado para garantizar que nuestro sitio web esté protegido y cumpla con las demandas de los sitios modernos de hoy.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Los clientes y visitantes de nuestro sitio, independientemente del dispositivo que utilicen, pueden confiar y tener la certeza de que su sesión de navegación es segura y que los detalles de pago y la información personal se mantienen seguros y encriptados.',
            },
            {
              bold: true,
              type: 'paragraph',
              text: '¿Qué es un Certificado SSL?',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Los certificados SSL son pequeños archivos de datos que vinculan digitalmente una clave criptográfica a los detalles de una organización. Cuando se instala en un servidor web, activa el candado y el protocolo https y permite conexiones seguras desde un servidor web a un navegador. Por lo general, SSL se utiliza para proteger las transacciones con tarjetas de crédito, la transferencia de datos y los inicios de sesión y, más recientemente, se está convirtiendo en la norma para proteger la navegación en los sitios de redes sociales.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Una organización necesita instalar el Certificado SSL en su servidor web para iniciar una sesión segura con los navegadores. Una vez que se establece una conexión segura, todo el tráfico web entre el servidor web y el navegador web será seguro.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: `Cuando un certificado se instala con éxito en su servidor, el protocolo de la aplicación (también conocido como HTTP) cambiará a HTTPS, donde la 'S' significa 'seguro'.`,
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Solución de problemas de pedido fallido',
      descriptions: [
        {
          bold: true,
          type: 'accordion',
          text: '¿Por qué mi tarjeta fue rechazada?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Esto podría deberse a una de las siguientes razones:',
            },
            {
              bold: false,
              type: 'list',
              list: [
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'El número de tarjeta se ingresó incorrectamente.',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'El nombre o apellido en la sección de facturación no coincidía con el nombre en la tarjeta.',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'El código "CVV" de tres dígitos en el reverso de la tarjeta se ingresó incorrectamente.',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'La dirección que ingresó en la sección de facturación de pago no coincide con la dirección de facturación de la tarjeta.',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'La tarjeta fue rechazada por fondos insuficientes.',
                },
              ],
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: '¿Ofrecen descuentos o promociones especiales?',
          details: [],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Envíos',
      descriptions: [
        {
          bold: true,
          type: 'accordion',
          text: '¿Realizan envíos internacionales?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Sí, realizamos envíos internacionales. Trabajamos con socios logísticos confiables para garantizar que tus refacciones lleguen a cualquier parte del mundo de manera segura y puntual.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: '¿Cuánto tiempo tarda en llegar mi pedido pendiente?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Los productos pendientes tienen un tiempo de entrega promedio de 3 a 5 semanas. Si han pasado más de 4 semanas desde que ordenó su producto, no dude en comunicarse con nuestro equipo de atención al cliente dedicado para obtener un tiempo estimado de entrega.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Los plazos de entrega estimados no siempre están disponibles de inmediato. A menudo tendremos que comunicarnos con nuestro proveedor para obtener una fecha de entrega estimada. En tsiparts.net, nos dedicamos a proporcionar a nuestros clientes un servicio de atención al cliente y una investigación de piezas oportunos y exhaustivos, y nuestra relación con cientos de proveedores de piezas de montacargas nos permite acceder a más de 8,2 millones de piezas. En ocasiones, nuestros fabricantes y proveedores de piezas tardan más de lo esperado en suministrar un producto.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: '¿Cuál es el costo de envío?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'El costo de envío se calcula en función del peso, tamaño y destino de tu pedido. Durante el proceso de pago, se te proporcionará el monto exacto del envío antes de confirmar tu compra.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: '¿Cómo puedo realizar un seguimiento de mi pedido?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Realizar un seguimiento de tu pedido es fácil y conveniente. Una vez que tu pedido haya sido procesado y enviado, recibirás un correo electrónico de confirmación con los detalles de seguimiento y un enlace para rastrear tu paquete. Simplemente haz clic en el enlace proporcionado o visita la sección "Seguimiento de Pedido" en nuestro sitio web e ingresa el número de seguimiento que se te ha proporcionado. Esto te permitirá conocer el estado actualizado de tu envío y realizar un seguimiento en tiempo real hasta que llegue a tu destino. Si tienes alguna dificultad para realizar el seguimiento de tu pedido, no dudes en comunicarte con nuestro equipo de servicio al cliente y estaremos encantados de ayudarte a obtener la información que necesitas.',
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Políticas',
      descriptions: [
        {
          bold: true,
          type: 'accordion',
          text: '¿Cuál es su política de devoluciones y cambios? ',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Reembolsos',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Una vez que tu solicitud de devolución es recibida e inspeccionada, enviaremos un correo electrónico para notificarte que hemos recibido tu devolución. También te notificaremos la aprobación o rechazo de tu reembolso. Si eres aprobado, entonces tu reembolso será procesado y un crédito será automáticamente aplicado a tu tarjeta de crédito o método original de pago, en 60 días hábiles.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Reembolsos tardíos o perdidos',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Si aún no has recibido tu reembolso, por favor revisa tu cuenta bancaria otra vez. Contacta a tu banco, a menudo pasa cierto tiempo antes de que tu reembolso se haga válido.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Si has seguido todos estos pasos y aún no has recibido el reembolso, por favor contáctanos.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: '¿Puedo cancelar mi pedido? ¿Cuáles son las condiciones?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Los pedidos no se pueden cancelar después de que se hayan procesado.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: '¿Ofrecen garantía en las refacciones?',
          details: [],
        },
        {
          bold: true,
          type: 'accordion',
          text: '¿Cuál es su política de privacidad y protección de datos?',
          details: [
            {
              bold: false,
              type: 'textButton',
              text: 'Políticas de privacidad',
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Ayuda/Servicio al cliente',
      descriptions: [
        {
          bold: true,
          type: 'accordion',
          text: '¿Tienen un equipo de servicio al cliente disponible para consultas y soporte técnico?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Sí, contamos con un equipo de servicio al cliente altamente capacitado y disponible para brindarte asistencia en todas tus consultas y necesidades de soporte técnico. Te ayudaremos a  obtener asesoramiento para seleccionar la refacción adecuada o resolver cualquier duda.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Nuestro objetivo es brindarte una atención rápida y eficiente para garantizar tu satisfacción y resolver cualquier duda o problema que puedas tener con respecto a nuestras refacciones o el funcionamiento de tu equipo. Estamos aquí para ayudarte en cada paso del camino.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: '¿Cómo puedo contactar al servicio de atención al cliente?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Puedes comunicarte con nosotros a través de diversos canales, como correo electrónico, chat en vivo o por teléfono.',
            },
          ],
        },
      ],
    },
  ],
};

export const helpAndSupportEn = {
  id: uuid(),
  title: 'Help',
  descriptions: [
    {
      bold: false,
      type: 'paragraph',
      text: 'Welcome to our Help page, the comprehensive support center for all our valued customers.',
    },
    {
      bold: false,
      type: 'paragraph',
      text: 'Here you will find resources and tools designed to answer all your questions.',
    },
  ],
  sections: [
    {
      id: uuid(),
      title: 'Assistance Service',
      descriptions: [
        {
          bold: false,
          type: 'paragraph',
          text: 'For more information or assistance regarding our e-commerce site® or TSIPARTS, contact us at:',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'T S TOTAL SOLUTION LLC',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Headquarters Nogales, Arizona, USA. 940 N Perkins Ave. Nogales, AZ 85621. USA',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Tel: +1 (520)540-6906',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Email: soporte@tsiparts.net',
        },
        {
          bold: false,
          type: 'paragraph',
          text: 'Office hours: 8:30 a.m. - 5:30 p.m. (Pacific Time) Monday to Friday',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Payment',
      descriptions: [
        {
          bold: true,
          type: 'accordion',
          text: 'What payment methods are accepted?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Cards are a common way for consumers and businesses to pay online or in person. We support various card brands, from major global networks like Visa and Mastercard to local networks like Cartes Bancaires in France or Interac in Canada. We accept a variety of card brands without additional setup, including:',
            },
            {
              bold: false,
              type: 'list',
              list: [
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'American Express',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'China UnionPay (CUP)',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'Discover & Diners Club',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'eftpos Australia',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'Japan Credit Bureau (JCB)',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'Mastercard',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'Visa',
                },
              ],
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: 'Is it safe to make payments on your online store?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: "Our site has an SSL certificate that provides the strongest encryption and value-added features to ensure our website is protected and meets the demands of today's modern sites.",
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Customers and visitors to our site, regardless of the device they use, can trust and be assured that their browsing session is secure and that payment details and personal information are kept safe and encrypted.',
            },
            {
              bold: true,
              type: 'paragraph',
              text: 'What is an SSL Certificate?',
            },
            {
              bold: false,
              type: 'paragraph',
              text: "SSL certificates are small data files that digitally bind a cryptographic key to an organization's details. When installed on a web server, it activates the padlock and the https protocol and allows secure connections from a web server to a browser. SSL is typically used to secure credit card transactions, data transfer, and logins, and more recently is becoming the norm to secure browsing on social media sites.",
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'An organization needs to install the SSL Certificate on its web server to initiate a secure session with browsers. Once a secure connection is established, all web traffic between the web server and the web browser will be secure.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: "When a certificate is successfully installed on your server, the application protocol (also known as HTTP) will change to HTTPS, where the 'S' stands for 'secure'.",
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Troubleshooting Failed Orders',
      descriptions: [
        {
          bold: true,
          type: 'accordion',
          text: 'Why was my card declined?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'This could be due to one of the following reasons:',
            },
            {
              bold: false,
              type: 'list',
              list: [
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'The card number was entered incorrectly.',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'The name or surname in the billing section did not match the name on the card.',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: "The three-digit 'CVV' code on the back of the card was entered incorrectly.",
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'The address you entered in the payment billing section does not match the billing address of the card.',
                },
                {
                  bold: false,
                  type: 'paragraph',
                  text: 'The card was declined due to insufficient funds.',
                },
              ],
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: 'Do you offer discounts or special promotions?',
          details: [],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Shipping',
      descriptions: [
        {
          bold: true,
          type: 'accordion',
          text: 'Do you ship internationally?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Yes, we ship internationally. We work with reliable logistics partners to ensure your parts reach anywhere in the world safely and on time.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: 'How long does it take for my pending order to arrive?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Pending products have an average delivery time of 3 to 5 weeks. If it has been more than 4 weeks since you ordered your product, feel free to contact our dedicated customer service team for an estimated delivery time.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Estimated delivery times are not always immediately available. We often have to reach out to our supplier for an estimated delivery date. At tsiparts.net, we are committed to providing our customers with timely and comprehensive customer service and parts research, and our relationship with hundreds of forklift parts suppliers allows us to access over 8.2 million parts. Occasionally, our manufacturers and parts suppliers take longer than expected to supply a product.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: 'What is the shipping cost?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Shipping cost is calculated based on the weight, size, and destination of your order. During the checkout process, you will be provided with the exact shipping amount before confirming your purchase.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: 'How can I track my order?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: "Tracking your order is easy and convenient. Once your order has been processed and shipped, you will receive a confirmation email with tracking details and a link to track your package. Simply click on the provided link or visit the 'Order Tracking' section on our website and enter the tracking number provided to you. This will allow you to know the current status of your shipment and track it in real-time until it reaches its destination. If you encounter any difficulty tracking your order, feel free to contact our customer service team, and we will be happy to assist you in obtaining the information you need.",
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Policies',
      descriptions: [
        {
          bold: true,
          type: 'accordion',
          text: 'What is your return and exchange policy?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Refunds',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Once your return request is received and inspected, we will send you an email to notify you that we have received your return. We will also notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within 60 business days.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Late or missing refunds',
            },
            {
              bold: false,
              type: 'paragraph',
              text: "If you haven't received your refund yet, please check your bank account again. Contact your bank, as it may take some time before your refund is officially posted.",
            },
            {
              bold: false,
              type: 'paragraph',
              text: "If you've done all of this and you still have not received your refund yet, please contact us.",
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: 'Can I cancel my order? What are the conditions?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Orders cannot be canceled once they have been processed.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: 'Do you offer warranties on parts?',
          details: [],
        },
        {
          bold: true,
          type: 'accordion',
          text: 'What is your privacy and data protection policy?',
          details: [
            {
              bold: false,
              type: 'textButton',
              text: 'Privacy Policies',
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Help/Customer Service',
      descriptions: [
        {
          bold: true,
          type: 'accordion',
          text: 'Do you have a customer service team available for inquiries and technical support?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'Yes, we have a highly trained customer service team available to assist you with all your inquiries and technical support needs. We will help you get advice on selecting the right part or resolve any questions you may have.',
            },
            {
              bold: false,
              type: 'paragraph',
              text: 'Our goal is to provide you with fast and efficient service to ensure your satisfaction and resolve any questions or issues you may have regarding our parts or the operation of your equipment. We are here to help you every step of the way.',
            },
          ],
        },
        {
          bold: true,
          type: 'accordion',
          text: 'How can I contact customer service?',
          details: [
            {
              bold: false,
              type: 'paragraph',
              text: 'You can contact us through various channels, such as email, live chat, or by phone.',
            },
          ],
        },
      ],
    },
  ],
};

export const policiesEs = {
  title: 'Políticas',
  descriptions: [],
  sections: [
    {
      id: uuid(),
      title: 'TÉRMINOS Y CONDICIONES',
      descriptions: [
        {
          type: 'paragraph',
          text: 'POR FAVOR LEA CUIDADOSAMENTE LOS SIGUIENTES TÉRMINOS Y CONDICIONES ANTES DE UTILIZAR ESTE SITIO WEB.',
        },
        {
          type: 'paragraph',
          text: 'Al visitar nuestro sitio y/o comprar algo de nosotros, usted acepta los siguientes términos y condiciones, incluidos todos los términos y condiciones adicionales y las políticas a las que se hace referencia en este documento y/o están disponibles a través de hipervínculos.',
        },
        {
          type: 'paragraph',
          text: 'Estos Términos de servicio se aplican a todos los usuarios del sitio, incluida su limitación a los usuarios que son navegadores, proveedores, clientes, comerciantes y/o contribuyentes de contenido.',
        },
        {
          type: 'paragraph',
          text: 'Si no está de acuerdo con estos términos y condiciones, no use este sitio.',
        },
      ]
    },
    {
      id: uuid(),
      title: 'TÉRMINOS Y CONDICIONES DE USO',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Las marcas comerciales que aparecen en el sitio pueden ser propiedad y/o estar registradas por terceros distintos de tsindustrial.com, como se indica mediante atribución expresa o la clara implicación del contexto. Ninguna representación contenida en el sitio debe interpretarse como una autorización para la reproducción, retransmisión u otro uso de las marcas comerciales de tsindustrial.com o de otras partes. La información de esta página solo se puede descargar, copiar o reproducir con el fin de realizar pedidos u obtener información sobre los artículos vendidos por tsindustrial.com.',
        },
      ]
    },
    {
      id: uuid(),
      title: 'TÉRMINOS Y CONDICIONES GENERALES DE VENTA',
      descriptions: [
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Precios',
        },
        {
          type: 'paragraph',
          text: 'Los precios y la información que se muestran en este portal están sujetos a cambios sin previo aviso. A menos que se indique lo contrario, todos los precios están en dólares estadounidenses USD.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Venta',
        },
        {
          type: 'paragraph',
          text: 'Nos reservamos el derecho, por cualquier motivo, a nuestro exclusivo criterio, de modificar, finalizar, cambiar, suspender o interrumpir cualquier aspecto de nuestro sitio (o cualquier parte del contenido), incluidos, entre otros, el contenido, las características o las horas de disponibilidad e imponer límites a ciertas características de nuestro sitio o restringir su acceso a parte o todo nuestro sitio en cualquier momento sin previo aviso.',
        },
        {
          type: 'paragraph',
          text: 'La información y los materiales contenidos en este portal son precisos según nuestro leal saber y entender; sin embargo, puede contener información técnica inexacta, errores tipográficos y/o inconsistencias. Tsindustrial.com no se hace responsable de los errores tipográficos.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Aceptación:',
        },
        {
          type: 'paragraph',
          text: 'Todos los pedidos están sujetos a la aprobación de TSIndustrial. Los siguientes términos y condiciones se aplican a todos los pedidos y están sujetos a cambios sin previo aviso. TSIndustrial se reserva el derecho de cancelar pedidos aceptados si hay circunstancias fuera de nuestro control, incluyendo (incendios, inundaciones, huelgas, disputas laborales, accidentes, sabotaje, terrorismo, guerras, disturbios, retrasos del transportista o falta de capacidad para enviar, restricciones impuestas por la ley , escasez de materiales y motivos similares) y otras causas que imposibiliten la entrega del pedido.',
        },
      ]
    },
    {
      id: uuid(),
      title: 'CONDICIONES DE ENVÍOS Y TRANSPORTE:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Gastos de flete terrestre.',
        },
        {
          type: 'paragraph',
          text: 'Existen diferentes tarifas dependiendo del valor en dólares del pedido ESTRICTAMENTE POR VÍA TERRESTRE.',
        },
        {
          underline: true,
          italic: true,
          type: 'numeric',
          text: 'Para México son las siguientes políticas de flete terrestre:',
          numeric: [
            {
              type: 'list',
              text: 'Todas las órdenes destinadas dentro de La República Mexicana que sean de $1,000.00 USD o más serán despachadas y facturadas con servicio pre pagado, usando la paquetería elegida por el proveedor (órdenes no acumuladas). Órdenes pre-pagadas aplican solamente a productos en inventario y no incluyen los siguientes artículos: ',
              list: [
                {
                  type: 'paragraph',
                  text: 'Horquillas',
                },
                {
                  type: 'paragraph',
                  text: 'Motores',
                },
                {
                  type: 'paragraph',
                  text: 'Llantas',
                },
                {
                  type: 'paragraph',
                  text: 'Patines',
                },
                {
                  type: 'paragraph',
                  text: 'Cargadores',
                },
                {
                  type: 'paragraph',
                  text: 'Tanques',
                },
                {
                  type: 'paragraph',
                  text: 'Aditamentos',
                },
                {
                  type: 'paragraph',
                  text: 'Prensas',
                },
                {
                  type: 'paragraph',
                  text: 'Mástil',
                },
                {
                  type: 'paragraph',
                  text: 'Masas',
                },
              ],
            },
            {
              type: 'columns',
              text: 'Todas las órdenes dentro de La República Mexicana cuyo monto sea mayor o menor a $1,000 USD antes de impuestos serán facturadas de la siguiente manera: ',
              columns: [
                {
                  type: 'row',
                  text: 'Monto Facturados (USD)',
                  row: [
                    {
                      type: 'paragraph',
                      text: '$0.00 hasta $999.99',
                    },
                    {
                      type: 'paragraph',
                      text: 'Mayor a $1000.00',
                    },
                  ],
                },
                {
                  type: 'row',
                  text: 'Costo de Flete y Manejo (USD)',
                  row: [
                    {
                      type: 'paragraph',
                      text: '$15.00 USD',
                    },
                    {
                      type: 'paragraph',
                      text: 'Gratuito',
                    },
                  ],
                },
              ],
            },
            {
              type: 'list',
              text: 'Órdenes incluyendo el siguiente material se les cobrará la tarifa decidida por la fletera sin importar el valor de la orden.',
              list: [
                {
                  type: 'paragraph',
                  text: 'Horquillas',
                },
                {
                  type: 'paragraph',
                  text: 'Motores',
                },
                {
                  type: 'paragraph',
                  text: 'Llantas',
                },
                {
                  type: 'paragraph',
                  text: 'Patines',
                },
                {
                  type: 'paragraph',
                  text: 'Cargadores',
                },
                {
                  type: 'paragraph',
                  text: 'Tanques',
                },
                {
                  type: 'paragraph',
                  text: 'Aditamentos',
                },
                {
                  type: 'paragraph',
                  text: 'Prensas',
                },
                {
                  type: 'paragraph',
                  text: 'Mástil',
                },
                {
                  type: 'paragraph',
                  text: 'Masas',
                },
              ],
            },
            {
              type: 'paragraph',
              text: 'Las partes pendientes (backorders) serán consolidadas con otros artículos que se encuentren disponibles en nuestro almacén a menos que nos avise de forma diferente. ',
            },
            {
              type: 'paragraph',
              text: 'Todos nuestros precios son L.A.B. (Libre A Bordo) (Es necesario especificar si necesita seguro).',
            },
          ]
        },
        {
          underline: true,
          italic: true,
          type: 'numeric',
          text: 'Para Estados Unidos son las siguientes políticas de flete terrestre:',
          numeric: [
            {
              type: 'list',
              text: 'Todos los pedidos de Estados Unidos continentales de más de $650 USD en valor neto se enviarán y facturarán con flete de superficie prepago utilizando el transportista elegido por el proveedor. Los pedidos prepagos se aplican únicamente a productos en existencia y no incluyen lo siguiente:',
              list: [
                {
                  type: 'paragraph',
                  text: 'Horquillas (incluidas extensiones de horquilla)',
                },
                {
                  type: 'paragraph',
                  text: 'motores',
                },
                {
                  type: 'paragraph',
                  text: 'Neumáticos individuales (75 lbs y más)',
                },
                {
                  type: 'paragraph',
                  text: 'Ruedas individuales (75 lbs y más)',
                },
                {
                  type: 'paragraph',
                  text: 'Transpaletas',
                },
                {
                  type: 'paragraph',
                  text: 'Cargador individual (75 lbs y más)',
                },
                {
                  type: 'paragraph',
                  text: 'Accesorio individual y respaldo de carga (75 lbs y más)',
                },
                {
                  type: 'paragraph',
                  text: 'Tanques de Gas LP',
                },
                {
                  type: 'paragraph',
                  text: 'Turnos laterales',
                },
                {
                  type: 'paragraph',
                  text: 'Tableros de flechas',
                },
                {
                  type: 'paragraph',
                  text: 'Productos de envío directo',
                },
              ],
            },
            {
              type: 'columns',
              text: 'Todos los pedidos de Estados Unidos continentales de menos de $650 USD en valor neto se facturarán de la siguiente manera: (Todos los montos son en dólares estadounidenses USD) ',
              columns: [
                {
                  type: 'row',
                  text: 'Monto de la factura',
                  row: [
                    {
                      type: 'paragraph',
                      text: '$0.00 to $24.99',
                    },
                    {
                      type: 'paragraph',
                      text: '$25.00 to $149.99',
                    },
                    {
                      type: 'paragraph',
                      text: '$150.00 to $299.99',
                    },
                    {
                      type: 'paragraph',
                      text: '$300.00 to $499.99',
                    },
                  ],
                },
                {
                  type: 'row',
                  text: 'Cargos de flete y manipulación',
                  row: [
                    {
                      type: 'paragraph',
                      text: '$16.00',
                    },
                    {
                      type: 'paragraph',
                      text: '$23.00',
                    },
                    {
                      type: 'paragraph',
                      text: '$28.00',
                    },
                    {
                      type: 'paragraph',
                      text: '$33.00',
                    },
                  ],
                },
              ],
            },
            {
              type: 'paragraph',
              text: 'Todos los pedidos de Estados Unidos continentales superiores a $2500 en valor neto se enviarán y facturarán con flete de superficie prepago utilizando el transportista elegido por el proveedor. Los pedidos prepagos se aplican a productos en stock. La única exclusión son los productos de envío directo.',
            },
            {
              type: 'paragraph',
              text: 'En un esfuerzo por evitar daños al producto, cualquier producto individual pedido de más de 75 libras se enviará LTL. Cualquier pedido combinado de más de 300 libras está sujeto a envío LTL. Los cargos de flete se aplicarán según los términos anteriores.',
            },
            {
              type: 'paragraph',
              text: 'A los pedidos terrestres contra reembolso se les cobrarán los cargos de flete y manipulación mencionados anteriormente más la tarifa contra reembolso si el valor del pedido es inferior a $650 y la tarifa contra reembolso solo si el valor del pedido es superior a $650. Todos los pedidos especiales contra reembolso se cobrarán a la tarifa del transportista más la tarifa contra reembolso.',
            },
            {
              type: 'paragraph',
              text: 'LOS PEDIDOS PENDIENTES TERRESTRE se enviarán con flete prepago utilizando el transportista elegido por el proveedor.',
            },
            {
              type: 'paragraph',
              text: 'El flete gratuito para pedidos no se aplica a pedidos enviados a Alaska, Hawái y Puerto Rico.',
            },
            {
              type: 'paragraph',
              text: 'Cualquier desviación de esta política de flete anularía beneficios tales como envíos terrestres gratuitos de pedidos pendientes o envíos terrestres gratuitos de más de $650,00.',
            },
          ],
        },
      ]
    },
    {
      id: uuid(),
      title: 'DEVOLUCIONES Y CANCELACIONES',
      descriptions: [
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Cancelaciones:',
        },
        {
          type: 'paragraph',
          text: 'Los pedidos no se pueden cancelar después de que se hayan procesado.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Devoluciones Generales:',
        },
        {
          type: 'paragraph',
          text: 'Todas las devoluciones deben solicitarse en un plazo máximo de 30 días desde la fecha de emisión de la factura. SÓLO se aceptarán devoluciones de mercancía con el número RA (Return-Authorization). Pedidos de productos especiales como Pinturas, Lubricantes, Tanques de GLP, Productos Químicos y Extintores; no son retornables ni cancelables. Las piezas deben ser devueltas a TSIndustrial a portes pagados por el remitente.',
        },
        {
          type: 'paragraph',
          text: 'Todas las devoluciones (a excepción de aquellas por defecto del producto o pieza equivocada) estarán sujetas a un cargo del 25% del valor del producto por concepto de reposición.',
        },
        {
          type: 'paragraph',
          text: 'Aquellos productos devueltos por defectos serán inspeccionados y probados. Toda mercancía que se verifique como defectuosa, ya criterio exclusivo de TSIndustrial, será reparada o reemplazada sin cargo o se emitirá nota de crédito por el valor incluido el flete. Las piezas devueltas como defectuosas una vez comprobado que funcionan correctamente, estarán sujetas a un cargo del 25% del valor por reposición.',
        },
        {
          type: 'paragraph',
          text: 'No se emitirá crédito por mercancía dañada por materiales corrosivos, abuso, alteración o mal uso.',
        },
        {
          type: 'numeric',
          text: 'Antes de devolver cualquier mercancía a TSIndustrial, su representante de ventas, departamento de servicio al cliente o por correo debe generar un número de autorización de devolución "Número de autorización". Su solicitud debe incluir:',
          numeric: [
            {
              type: 'paragraph',
              text: 'Número de pieza y descripción',
            },
            {
              type: 'paragraph',
              text: 'Motivo de la devolución',
            },
            {
              type: 'paragraph',
              text: 'Número de factura de TSIndustrial',
            },
            {
              type: 'paragraph',
              text: 'Número de pedido del cliente',
            },
          ],
        },
      ]
    },
    {
      id: uuid(),
      title: 'POLÍTICAS DE PRIVACIDAD',
      descriptions: [
        {
          type: 'paragraph',
          text: 'TS INDUSTRIAL se esfuerza por proteger su privacidad en línea mientras le brinda la experiencia web más útil y agradable posible. TS INDUSTRIAL se compromete a proteger su privacidad y desarrollar tecnología que le brinde la experiencia en línea más poderosa y segura. Esta Declaración de privacidad se aplica al sitio web www.tsindustrial.com y cubre la recopilación y el uso de datos.',
        },
        {
          type: 'paragraph',
          text: 'Al usar este sitio web, usted acepta las prácticas de datos descritas en esta declaración. Los usuarios pueden acceder a muchas funciones de nuestro sitio de forma anónima; sin embargo, es posible que se le solicite que proporcione información personal cuando participe en concursos, encuestas o proporcione comentarios o cuando utilice otras áreas interactivas de nuestro sitio. El uso de nuestro sitio implica su comprensión y aceptación de los términos de esta política de privacidad.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Cómo recopilamos y usamos la información personal',
        },
        {
          type: 'paragraph',
          text: 'Recopilamos información de nuestros usuarios en varios puntos diferentes de nuestro sitio. En general, cada usuario tiene acceso a toda la información y funciones disponibles en nuestro sitio. Sin embargo, a los usuarios que elijan ser informados sobre la disponibilidad de productos se les pedirá que proporcionen información específica del usuario.',
        },
        {
          type: 'list',
          text: 'Utilizaremos los datos personales que recabamos de usted para las siguientes finalidades que son necesarias para el servicio que solicita:',
          list: [
            {
              type: 'paragraph',
              text: 'Responder a las solicitudes de información sobre productos.',
            },
            {
              type: 'paragraph',
              text: 'Informar sobre actualizaciones de nuestros productos.',
            },
            {
              type: 'paragraph',
              text: 'Conocer el rendimiento de nuestro servicio.',
            },
            {
              type: 'paragraph',
              text: 'Para procesar los pagos, podemos utilizar la información que los Usuarios proporcionan sobre sí mismos al realizar un pedido solo para brindar servicio a ese pedido.',
            },
            {
              type: 'paragraph',
              text: 'Para enviar correos electrónicos periódicos, podemos usar la dirección de correo electrónico para enviar información del usuario y actualizaciones relacionadas con su pedido. También podrá ser utilizado para responder a sus consultas, preguntas y/u otras solicitudes.',
            },
          ],
        },
        {
          type: 'paragraph',
          text: 'Adicionalmente, utilizaremos su información personal para las siguientes finalidades que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle un mejor servicio. Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales: nombre completo, correo electrónico, fecha de nacimiento, ciudad de origen y número de teléfono.',
        },
        {
          type: 'paragraph',
          text: 'También existe información sobre el hardware y software de su ordenador o dispositivo personal, que TS INDUSTRIAL recopila automáticamente. Esta información puede incluir: su dirección IP, tipo de navegador de Internet utilizado, nombres de dominio, tiempos de acceso y direcciones de sitios web que lo remiten. Esta información es utilizada por TS INDUSTRIAL para la operación del servicio, para mantener la calidad del servicio prestado y para brindar estadísticas generales acerca del uso del sitio www.tsindustrial.com.',
        },
        {
          type: 'paragraph',
          text: 'TS INDUSTRIAL no tiene que vender, alquilar o ceder sus listas de clientes a terceros.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Registro al sitio:',
        },
        {
          type: 'paragraph',
          text: 'A los usuarios que elijan registrarse en nuestros sitios se les pedirá que proporcionen información de contacto (como nombre y dirección de correo electrónico). Usamos esta información, por ejemplo, para comunicarnos con el usuario sobre los servicios en nuestros sitios por los que ha expresado interés. Es generalmente es opcional que el usuario proporcione información demográfica (como el nivel de ingresos y el género) e identificadores únicos (como el nombre de usuario y la contraseña), pero se recomienda para que podamos brindar una experiencia más personalizada en nuestros sitios.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Cómo protegemos su información:',
        },
        {
          type: 'paragraph',
          text: 'Adoptamos prácticas adecuadas de recopilación, almacenamiento y procesamiento de datos y medidas de seguridad para proteger su información personal del acceso, uso o divulgación no autorizados. TS INDUSTRIAL mantiene la información de identificación personal que usted ha proporcionado, en servidores ubicados en un entorno controlado y seguro, protegido del acceso, uso o divulgación no autorizados.',
        },
        {
          type: 'paragraph',
          text: 'El intercambio de datos confidenciales y privados entre el Sitio y los Usuarios se realiza a través de un canal de comunicación seguro SSL encriptado y protegido con firmas digitales.',
        },
      ]
    },
  ],
};

export const policiesEn = {
  title: 'Policies',
  descriptions: [],
  sections: [
    {
      id: uuid(),
      title: 'TERMS AND CONDITIONS',
      descriptions: [
        {
          type: 'paragraph',
          text: 'PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS WEBSITE.',
        },
        {
          type: 'paragraph',
          text: 'By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink.',
        },
        {
          type: 'paragraph',
          text: 'These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.',
        },
        {
          type: 'paragraph',
          text: 'If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.',
        },
      ]
    },
    {
      id: uuid(),
      title: 'TERMS AND CONDITIONS OF USE',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Trademarks appearing on the site may be owned and/or registered by third parties other than tsindustrial.com, as indicated by express attribution or the clear implication of the context. No representation contained on the site should be construed as granting, by implication or otherwise, any license or right to use any trademarks of tsindustrial.com or any other party. The information on this page may only be downloaded, copied or reproduced for the purpose of placing orders or obtaining information about items sold by tsindustrial.com.',
        },
      ]
    },
    {
      id: uuid(),
      title: 'GENERAL TERMS AND CONDITIONS OF SALE',
      descriptions: [
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Prices',
        },
        {
          type: 'paragraph',
          text: 'The prices and information displayed on this portal are subject to change without notice. Unless otherwise indicated, all prices are in US dollars USD.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Sale',
        },
        {
          type: 'paragraph',
          text: 'We reserve the right, for any reason, in our sole discretion, to modify, terminate, change, suspend or discontinue any aspect of our site (or any part thereof including the content), including but not limited to content, features or hours of availability and impose limits on certain features of our site or restrict your access to part or all of our site at any time without notice.',
        },
        {
          type: 'paragraph',
          text: 'The information and materials contained in this portal are accurate to the best of our knowledge and understanding; however, it may contain inaccurate technical information, typographical errors, and/or inconsistencies. Tsindustrial.com is not responsible for typographical errors.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Acceptance:',
        },
        {
          type: 'paragraph',
          text: 'All orders are subject to approval by TSIndustrial. The following terms and conditions apply to all orders and are subject to change without notice. TSIndustrial reserves the right to cancel accepted orders if there are circumstances beyond our control, including (fires, floods, strikes, labor disputes, accidents, sabotage, terrorism, wars, riots, carrier delays or inability to ship, restrictions imposed by law, shortage of materials, and similar reasons) and other causes that make it impossible to deliver the order.',
        },
      ]
    },
    {
      id: uuid(),
      title: 'SHIPPING AND TRANSPORTATION CONDITIONS:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Ground freight charges.',
        },
        {
          type: 'paragraph',
          text: 'There are different rates depending on the dollar value of the order STRICTLY BY LAND.',
        },
        {
          underline: true,
          italic: true,
          type: 'numeric',
          text: 'For Mexico, the following ground freight policies apply:',
          numeric: [
            {
              type: 'list',
              text: 'All orders destined within the Mexican Republic that are $1,000.00 USD or more will be dispatched and invoiced with prepaid service, using the courier chosen by the provider (non-accumulated orders). Prepaid orders apply only to inventory products and do not include the following items: ',
              list: [
                {
                  type: 'paragraph',
                  text: 'Forks',
                },
                {
                  type: 'paragraph',
                  text: 'Motors',
                },
                {
                  type: 'paragraph',
                  text: 'Tires',
                },
                {
                  type: 'paragraph',
                  text: 'Skates',
                },
                {
                  type: 'paragraph',
                  text: 'Chargers',
                },
                {
                  type: 'paragraph',
                  text: 'Tanks',
                },
                {
                  type: 'paragraph',
                  text: 'Attachments',
                },
                {
                  type: 'paragraph',
                  text: 'Presses',
                },
                {
                  type: 'paragraph',
                  text: 'Mast',
                },
                {
                  type: 'paragraph',
                  text: 'Masses',
                },
              ],
            },
            {
              type: 'columns',
              text: 'All orders within the Mexican Republic whose amount is greater or less than $1,000 USD before taxes will be invoiced as follows: ',
              columns: [
                {
                  type: 'row',
                  text: 'Billed Amount (USD)',
                  row: [
                    {
                      type: 'paragraph',
                      text: '$0.00 to $999.99',
                    },
                    {
                      type: 'paragraph',
                      text: 'Greater than $1000.00',
                    },
                  ],
                },
                {
                  type: 'row',
                  text: 'Freight and Handling Cost (USD)',
                  row: [
                    {
                      type: 'paragraph',
                      text: '$15.00 USD',
                    },
                    {
                      type: 'paragraph',
                      text: 'Free',
                    },
                  ],
                },
              ],
            },
            {
              type: 'list',
              text: 'Orders including the following material will be charged the rate decided by the carrier regardless of the order value.',
              list: [
                {
                  type: 'paragraph',
                  text: 'Forks',
                },
                {
                  type: 'paragraph',
                  text: 'Motors',
                },
                {
                  type: 'paragraph',
                  text: 'Tires',
                },
                {
                  type: 'paragraph',
                  text: 'Skates',
                },
                {
                  type: 'paragraph',
                  text: 'Chargers',
                },
                {
                  type: 'paragraph',
                  text: 'Tanks',
                },
                {
                  type: 'paragraph',
                  text: 'Attachments',
                },
                {
                  type: 'paragraph',
                  text: 'Presses',
                },
                {
                  type: 'paragraph',
                  text: 'Mast',
                },
                {
                  type: 'paragraph',
                  text: 'Masses',
                },
              ],
            },
            {
              type: 'paragraph',
              text: 'Backorders will be consolidated with other items that are available in our warehouse unless you notify us otherwise. ',
            },
            {
              type: 'paragraph',
              text: 'All our prices are L.A.B. (Free On Board) (It is necessary to specify if you need insurance).',
            },
          ]
        },
        {
          underline: true,
          italic: true,
          type: 'numeric',
          text: 'For the United States, the following ground freight policies apply:',
          numeric: [
            {
              type: 'list',
              text: 'All orders from continental United States over $650 USD in net value will be shipped and billed with prepaid surface freight using the carrier chosen by the provider. Prepaid orders apply only to in-stock products and do not include the following:',
              list: [
                {
                  type: 'paragraph',
                  text: 'Forks (including fork extensions)',
                },
                {
                  type: 'paragraph',
                  text: 'Motors',
                },
                {
                  type: 'paragraph',
                  text: 'Individual Tires (75 lbs and over)',
                },
                {
                  type: 'paragraph',
                  text: 'Individual Wheels (75 lbs and over)',
                },
                {
                  type: 'paragraph',
                  text: 'Pallet Jacks',
                },
                {
                  type: 'paragraph',
                  text: 'Individual Charger (75 lbs and over)',
                },
                {
                  type: 'paragraph',
                  text: 'Individual Attachment and Load Backrest (75 lbs and over)',
                },
                {
                  type: 'paragraph',
                  text: 'LP Gas Tanks',
                },
                {
                  type: 'paragraph',
                  text: 'Side Shifts',
                },
                {
                  type: 'paragraph',
                  text: 'Arrow Boards',
                },
                {
                  type: 'paragraph',
                  text: 'Drop Ship Products',
                },
              ],
            },
            {
              type: 'columns',
              text: 'All orders from continental United States under $650 USD in net value will be billed as follows: (All amounts are in US dollars USD) ',
              columns: [
                {
                  type: 'row',
                  text: 'Invoice Amount',
                  row: [
                    {
                      type: 'paragraph',
                      text: '$0.00 to $24.99',
                    },
                    {
                      type: 'paragraph',
                      text: '$25.00 to $149.99',
                    },
                    {
                      type: 'paragraph',
                      text: '$150.00 to $299.99',
                    },
                    {
                      type: 'paragraph',
                      text: '$300.00 to $499.99',
                    },
                  ],
                },
                {
                  type: 'row',
                  text: 'Freight and Handling Charges',
                  row: [
                    {
                      type: 'paragraph',
                      text: '$16.00',
                    },
                    {
                      type: 'paragraph',
                      text: '$23.00',
                    },
                    {
                      type: 'paragraph',
                      text: '$28.00',
                    },
                    {
                      type: 'paragraph',
                      text: '$33.00',
                    },
                  ],
                },
              ],
            },
            {
              type: 'paragraph',
              text: 'All orders from continental United States over $2500 in net value will be shipped and billed with prepaid surface freight using the carrier chosen by the provider. Prepaid orders apply to in-stock products only. The only exclusion is drop ship products.',
            },
            {
              type: 'paragraph',
              text: 'In an effort to avoid damage to the product, any individual product ordered over 75 pounds will be shipped LTL. Any combined order over 300 pounds is subject to LTL shipping. Freight charges will apply as per the above terms.',
            },
            {
              type: 'paragraph',
              text: 'Freight collect ground orders will be charged the above freight and handling charges plus the collect fee if the order value is less than $650 and the collect fee only if the order value is over $650. All special collect orders will be charged at the carrier rate plus the collect fee.',
            },
            {
              type: 'paragraph',
              text: 'GROUND PENDING ORDERS will be shipped with prepaid freight using the carrier chosen by the provider.',
            },
            {
              type: 'paragraph',
              text: 'Free freight for orders does not apply to orders shipped to Alaska, Hawaii, and Puerto Rico.',
            },
            {
              type: 'paragraph',
              text: 'Any deviation from this freight policy would nullify benefits such as free ground shipments of pending orders or free ground shipments over $650.00.',
            },
          ],
        },
      ]
    },
    {
      id: uuid(),
      title: 'RETURNS AND CANCELLATIONS',
      descriptions: [
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Cancellations:',
        },
        {
          type: 'paragraph',
          text: 'Orders cannot be canceled after they have been processed.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'General Returns:',
        },
        {
          type: 'paragraph',
          text: 'All returns must be requested within a maximum of 30 days from the date of the invoice. Returns of merchandise will ONLY be accepted with the RA (Return-Authorization) number. Orders for special products such as Paints, Lubricants, LP Tanks, Chemicals, and Fire Extinguishers; are not returnable or cancelable. Parts must be returned to TSIndustrial freight prepaid by the sender.',
        },
        {
          type: 'paragraph',
          text: 'All returns (except those for product defects or wrong parts) will be subject to a 25% charge of the product value for restocking.',
        },
        {
          type: 'paragraph',
          text: 'Products returned for defects will be inspected and tested. Any merchandise verified as defective, at the sole discretion of TSIndustrial, will be repaired or replaced at no charge or a credit note will be issued for the value including the freight. Parts returned as defective once it is verified that they work correctly will be subject to a 25% charge of the value for restocking.',
        },
        {
          type: 'paragraph',
          text: 'No credit will be issued for merchandise damaged by corrosive materials, abuse, alteration, or misuse.',
        },
        {
          type: 'numeric',
          text: 'Before returning any merchandise to TSIndustrial, your sales representative, customer service department, or by mail must generate a return authorization number "Authorization Number". Your request must include:',
          numeric: [
            {
              type: 'paragraph',
              text: 'Part number and description',
            },
            {
              type: 'paragraph',
              text: 'Reason for return',
            },
            {
              type: 'paragraph',
              text: 'TSIndustrial invoice number',
            },
            {
              type: 'paragraph',
              text: 'Customer order number',
            },
          ],
        },
      ]
    },
    {
      id: uuid(),
      title: 'PRIVACY POLICIES',
      descriptions: [
        {
          type: 'paragraph',
          text: 'TS INDUSTRIAL strives to protect your online privacy while providing you with the most useful and enjoyable web experience possible. TS INDUSTRIAL is committed to protecting your privacy and developing technology that gives you the most powerful and safe online experience. This Privacy Statement applies to the website www.tsindustrial.com and covers the collection and use of data.',
        },
        {
          type: 'paragraph',
          text: 'By using this website, you consent to the data practices described in this statement. Users can access many features of our site anonymously; however, you may be asked to provide personal information when participating in contests, surveys, or providing feedback or when using other interactive areas of our site. The use of our site implies your understanding and acceptance of the terms of this privacy policy.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'How we collect and use personal information',
        },
        {
          type: 'paragraph',
          text: 'We collect information from our users at various different points on our site. In general, each user has access to all the information and features available on our site. However, users who choose to be informed about product availability will be asked to provide specific user information.',
        },
        {
          type: 'list',
          text: 'We will use the personal data we collect from you for the following purposes that are necessary for the service you request:',
          list: [
            {
              type: 'paragraph',
              text: 'Respond to requests for information about products.',
            },
            {
              type: 'paragraph',
              text: 'Inform about updates to our products.',
            },
            {
              type: 'paragraph',
              text: 'Know the performance of our service.',
            },
            {
              type: 'paragraph',
              text: 'To process payments, we may use the information that Users provide about themselves when placing an order only to provide service to that order.',
            },
            {
              type: 'paragraph',
              text: 'For periodic emails, we may use the email address to send User information and updates related to their order. It may also be used to respond to their inquiries, questions, and/or other requests.',
            },
          ],
        },
        {
          type: 'paragraph',
          text: 'Additionally, we will use your personal information for the following purposes that are not necessary for the requested service, but that allow us and facilitate us to provide you with better service. To carry out the purposes described in this privacy notice, we will use the following personal data: full name, email address, date of birth, city of origin, and phone number.',
        },
        {
          type: 'paragraph',
          text: "There is also information about your computer or personal device's hardware and software that TS INDUSTRIAL automatically collects. This information may include: your IP address, internet browser type, domain names, access times, and referring website addresses. This information is used by TS INDUSTRIAL for the operation of the service, to maintain the quality of the service, and to provide general statistics regarding the use of the www.tsindustrial.com site.",
        },
        {
          type: 'paragraph',
          text: 'TS INDUSTRIAL does not have to sell, rent, or lease its customer lists to third parties.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Site registration:',
        },
        {
          type: 'paragraph',
          text: 'Users who choose to register on our sites will be asked to provide contact information (such as name and email address). We use this information, for example, to communicate with the user about services on our sites for which they have expressed interest. It is generally optional for the user to provide demographic information (such as income level and gender) and unique identifiers (such as username and password), but it is recommended so that we can provide a more personalized experience on our sites.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'How we protect your information:',
        },
        {
          type: 'paragraph',
          text: 'We adopt appropriate data collection, storage, and processing practices and security measures to protect your personal information from unauthorized access, use, or disclosure. TS INDUSTRIAL maintains the personally identifiable information you have provided on servers located in a controlled and secure environment, protected from unauthorized access, use, or disclosure.',
        },
        {
          type: 'paragraph',
          text: 'The exchange of confidential and private data between the Site and its Users occurs over a secure SSL encrypted communication channel and is protected with digital signatures.',
        },
      ]
    },
  ],
};

export const returnsPolicyEs = {
  title: 'DEVOLUCIONES Y CANCELACIONES',
  descriptions: [],
  sections: [
    {
      id: uuid(),
      title: 'Cancelaciones:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Los pedidos no se pueden cancelar después de que se hayan procesado.',
        },
      ]
    },
    {
      id: uuid(),
      title: 'Devoluciones Generales:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Todas las devoluciones deben solicitarse en un plazo máximo de 30 días desde la fecha de emisión de la factura. SÓLO se aceptarán devoluciones de mercancía con el número RA (Return-Authorization). Pedidos de productos especiales como Pinturas, Lubricantes, Tanques de GLP, Productos Químicos y Extintores; no son retornables ni cancelables. Las piezas deben ser devueltas a TSIndustrial a portes pagados por el remitente.',
        },
        {
          type: 'paragraph',
          text: 'Todas las devoluciones (a excepción de aquellas por defecto del producto o pieza equivocada) estarán sujetas a un cargo del 25% del valor del producto por concepto de reposición.',
        },
        {
          type: 'paragraph',
          text: 'Aquellos productos devueltos por defectos serán inspeccionados y probados. Toda mercancía que se verifique como defectuosa, ya criterio exclusivo de TSIndustrial, será reparada o reemplazada sin cargo o se emitirá nota de crédito por el valor incluido el flete. Las piezas devueltas como defectuosas una vez comprobado que funcionan correctamente, estarán sujetas a un cargo del 25% del valor por reposición.',
        },
        {
          type: 'paragraph',
          text: 'No se emitirá crédito por mercancía dañada por materiales corrosivos, abuso, alteración o mal uso.',
        },
        {
          type: 'numeric',
          text: 'Antes de devolver cualquier mercancía a TSIndustrial, su representante de ventas, departamento de servicio al cliente o por correo debe generar un número de autorización de devolución "Número de autorización". Su solicitud debe incluir:',
          numeric: [
            {
              type: 'paragraph',
              text: 'Número de pieza y descripción',
            },
            {
              type: 'paragraph',
              text: 'Motivo de la devolución',
            },
            {
              type: 'paragraph',
              text: 'Número de factura de TSIndustrial',
            },
            {
              type: 'paragraph',
              text: 'Número de pedido del cliente',
            },
          ],
        },
      ]
    },
  ],
};

export const returnsPolicyEn = {
  title: 'RETURNS AND CANCELLATIONS',
  descriptions: [],
  sections: [
    {
      id: uuid(),
      title: 'Cancellations:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Orders cannot be canceled after they have been processed.',
        },
      ]
    },
    {
      id: uuid(),
      title: 'General Returns',
      descriptions: [
        {
          type: 'paragraph',
          text: 'All returns must be requested within a maximum of 30 days from the date of the invoice. Returns of merchandise will ONLY be accepted with the RA (Return-Authorization) number. Orders for special products such as Paints, Lubricants, LP Tanks, Chemicals, and Fire Extinguishers; are not returnable or cancelable. Parts must be returned to TSIndustrial freight prepaid by the sender.',
        },
        {
          type: 'paragraph',
          text: 'All returns (except those for product defects or wrong parts) will be subject to a 25% charge of the product value for restocking.',
        },
        {
          type: 'paragraph',
          text: 'Products returned for defects will be inspected and tested. Any merchandise verified as defective, at the sole discretion of TSIndustrial, will be repaired or replaced at no charge or a credit note will be issued for the value including the freight. Parts returned as defective once it is verified that they work correctly will be subject to a 25% charge of the value for restocking.',
        },
        {
          type: 'paragraph',
          text: 'No credit will be issued for merchandise damaged by corrosive materials, abuse, alteration, or misuse.',
        },
        {
          type: 'numeric',
          text: 'Before returning any merchandise to TSIndustrial, your sales representative, customer service department, or by mail must generate a return authorization number "Authorization Number". Your request must include:',
          numeric: [
            {
              type: 'paragraph',
              text: 'Part number and description',
            },
            {
              type: 'paragraph',
              text: 'Reason for return',
            },
            {
              type: 'paragraph',
              text: 'TSIndustrial invoice number',
            },
            {
              type: 'paragraph',
              text: 'Customer order number',
            },
          ],
        },
      ]
    },
  ],
};

export const ourPolicyEs = {
  title: 'Politicas de checkout',
  descriptions: [],
  sections: [
    {
      id: uuid(),
      anchorId: ourPolicyAnchor.shippingAndFreightPolicy,
      title: 'Política de Envío y Flete de Partes TSI',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Nota: Toda la información siguiente es únicamente para pedidos terrestres.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Tarifas de Envío en los EE. UU.:',
      descriptions: [
        {
          type: 'list',
          text: 'Para pedidos realizados dentro de los Estados Unidos contiguos, las tarifas de envío son las siguientes:',
          list: [
            {
              type: 'paragraph',
              text: 'Nota: Los pedidos entre $650 y $2499.99 califican para envío terrestre gratuito si el cliente selecciona transporte terrestre. Los pedidos superiores a $2500 estarán sujetos a cargos de flete pagados.',
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Reglas para Artículos Pesados:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Nota: Cualquier pedido que contenga un solo artículo de más de 75 libras o un peso combinado de más de 300 libras será enviado vía flete de carga parcial (LTL), y se aplicarán cargos de envío.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Artículos Excluidos (Productos Pesados):',
      descriptions: [
        {
          type: 'columns',
          text: 'Los siguientes artículos están excluidos del envío gratuito y tienen políticas especiales de flete:',
          columns: [
            {
              type: 'row',
              text: 'Valor del Carrito',
              row: [
                {
                  type: 'paragraph',
                  text: '$0.00 a $24.99',
                },
                {
                  type: 'paragraph',
                  text: '$25.00 a $149.99',
                },
                {
                  type: 'paragraph',
                  text: '$150.00 a $299.99',
                },
                {
                  type: 'paragraph',
                  text: '$300.00 a $499.99',
                },
                {
                  type: 'paragraph',
                  text: '$500.00 a $649.99',
                },
                {
                  type: 'paragraph',
                  text: '$650.00 a $2499.99',
                },
                {
                  type: 'paragraph',
                  text: '$2500+',
                },
              ],
            },
            {
              type: 'row',
              text: 'Flete',
              row: [
                {
                  type: 'paragraph',
                  text: '$16.00',
                },
                {
                  type: 'paragraph',
                  text: '$23.00',
                },
                {
                  type: 'paragraph',
                  text: '$28.00',
                },
                {
                  type: 'paragraph',
                  text: '$33.00',
                },
                {
                  type: 'paragraph',
                  text: '$37.00',
                },
                {
                  type: 'paragraph',
                  text: 'Gratis*',
                },
                {
                  type: 'paragraph',
                  text: 'Pagado*',
                },
              ],
            },
          ],
        },
        {
          type: 'columns',
          columns: [
            {
              type: 'row',
              text: 'Regla de Peso',
              row: [
                {
                  type: 'paragraph',
                  text: 'Más de 75 lbs',
                },
                {
                  type: 'paragraph',
                  text: 'Más de 300 lbs',
                },
              ],
            },
            {
              type: 'row',
              text: 'Flete',
              row: [
                {
                  type: 'paragraph',
                  text: 'Flete LTL',
                },
                {
                  type: 'paragraph',
                  text: 'Flete LTL',
                },
              ],
            },
          ],
        },
        {
          type: 'paragraph',
          text: 'Horquillas (incluyendo extensiones de horquillas)',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Proceso de Cotización de Flete Personalizado:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Para artículos pesados o voluminosos, se solicitará una cotización de flete personalizada durante el pago. Una vez recibida la solicitud, el equipo de soporte de ventas de TSI Parts se pondrá en contacto con el cliente con una cotización de flete LTL. Luego, el pedido se actualizará y el cliente recibirá un correo electrónico con el costo final de envío para proceder con su compra.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Tarifas de Envío en MÉXICO:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Para pedidos realizados dentro de la República Mexicana contigua, las tarifas de envío son las siguientes:',
        },
        {
          type: 'paragraph',
          text: 'Nota: Todos los pedidos con destino dentro de la República Mexicana de $1,000.00 USD o más serán despachados y facturados con servicio prepagado, utilizando la paquetería elegida por el proveedor (pedidos no acumulables). Los pedidos prepagados solo aplican a productos en existencia y no incluyen los siguientes artículos:',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Artículos Excluidos (Material Especial):',
      descriptions: [
        {
          type: 'list',
          text: 'Los pedidos que incluyan el siguiente material serán cobrados según la tarifa decidida por la empresa de fletes, independientemente del valor del pedido:',
          list: [
            {
              type: 'paragraph',
              text: 'Motores',
            },
            {
              type: 'paragraph',
              text: 'Llantas individuales (75 lbs o más)',
            },
            {
              type: 'paragraph',
              text: 'Ruedas individuales (75 lbs o más)',
            },
            {
              type: 'paragraph',
              text: 'Patines para pallets',
            },
            {
              type: 'paragraph',
              text: 'Cargadores individuales (75 lbs o más)',
            },
            {
              type: 'paragraph',
              text: 'Accesorios individuales y respaldos de carga (75 lbs o más)',
            },
            {
              type: 'paragraph',
              text: 'Tanques de LPG',
            },
            {
              type: 'paragraph',
              text: 'Desplazadores laterales',
            },
          ],
        },
        {
          type: 'columns',
          columns: [
            {
              type: 'row',
              text: 'Valor del Carrito',
              row: [
                {
                  type: 'paragraph',
                  text: '$0.00 a $999.99',
                },
                {
                  type: 'paragraph',
                  text: '$1000.00+',
                },
              ],
            },
            {
              type: 'row',
              text: 'Flete',
              row: [
                {
                  type: 'paragraph',
                  text: '$15.00',
                },
                {
                  type: 'paragraph',
                  text: 'Gratis*',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Órdenes Pendientes (Back Orders):',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Las partes pendientes (backorders) se consolidarán con otros artículos que estén disponibles en nuestro almacén, a menos que se nos notifique lo contrario.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'POLÍTICA DE DEVOLUCIONES Y CANCELACIONES',
      descriptions: [
        {
          anchorId: ourPolicyAnchor.cancellations,
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Cancelaciones:',
        },
        {
          type: 'paragraph',
          text: 'Los pedidos no se pueden cancelar después de que se hayan procesado.',
        },
        {
          anchorId: ourPolicyAnchor.generalReturns,
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Devoluciones Generales:',
        },
        {
          type: 'paragraph',
          text: 'Todas las devoluciones deben solicitarse en un plazo máximo de 30 días desde la fecha de emisión de la factura. SÓLO se aceptarán devoluciones de mercancía con el número RA (Return-Authorization). Pedidos de productos especiales como Pinturas, Lubricantes, Tanques de GLP, Productos Químicos y Extintores; no son retornables ni cancelables. Las piezas deben ser devueltas a TSIndustrial a portes pagados por el remitente.',
        },
        {
          type: 'paragraph',
          text: 'Todas las devoluciones (a excepción de aquellas por defecto del producto o pieza equivocada) estarán sujetas a un cargo del 25% del valor del producto por concepto de reposición.',
        },
        {
          type: 'paragraph',
          text: 'Aquellos productos devueltos por defectos serán inspeccionados y probados. Toda mercancía que se verifique como defectuosa, ya criterio exclusivo de TSIndustrial, será reparada o reemplazada sin cargo o se emitirá nota de crédito por el valor incluido el flete. Las piezas devueltas como defectuosas una vez comprobado que funcionan correctamente, estarán sujetas a un cargo del 25% del valor por reposición.',
        },
        {
          type: 'paragraph',
          text: 'No se emitirá crédito por mercancía dañada por materiales corrosivos, abuso, alteración o mal uso.',
        },
        {
          type: 'numeric',
          text: 'Antes de devolver cualquier mercancía a TSIndustrial, su representante de ventas, departamento de servicio al cliente o por correo debe generar un número de autorización de devolución "Número de autorización". Su solicitud debe incluir:',
          numeric: [
            {
              type: 'paragraph',
              text: 'Número de pieza y descripción',
            },
            {
              type: 'paragraph',
              text: 'Motivo de la devolución',
            },
            {
              type: 'paragraph',
              text: 'Número de factura de TSIndustrial',
            },
            {
              type: 'paragraph',
              text: 'Número de pedido del cliente',
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'POLÍTICAS DE PRIVACIDAD',
      descriptions: [
        {
          type: 'paragraph',
          text: 'TS INDUSTRIAL se esfuerza por proteger su privacidad en línea mientras le brinda la experiencia web más útil y agradable posible. TS INDUSTRIAL se compromete a proteger su privacidad y desarrollar tecnología que le brinde la experiencia en línea más poderosa y segura. Esta Declaración de privacidad se aplica al sitio web www.tsindustrial.com y cubre la recopilación y el uso de datos.',
        },
        {
          type: 'paragraph',
          text: 'Al usar este sitio web, usted acepta las prácticas de datos descritas en esta declaración. Los usuarios pueden acceder a muchas funciones de nuestro sitio de forma anónima; sin embargo, es posible que se le solicite que proporcione información personal cuando participe en concursos, encuestas o proporcione comentarios o cuando utilice otras áreas interactivas de nuestro sitio. El uso de nuestro sitio implica su comprensión y aceptación de los términos de esta política de privacidad.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Cómo recopilamos y usamos la información personal',
        },
        {
          type: 'paragraph',
          text: 'Recopilamos información de nuestros usuarios en varios puntos diferentes de nuestro sitio. En general, cada usuario tiene acceso a toda la información y funciones disponibles en nuestro sitio. Sin embargo, a los usuarios que elijan ser informados sobre la disponibilidad de productos se les pedirá que proporcionen información específica del usuario.',
        },
        {
          type: 'list',
          text: 'Utilizaremos los datos personales que recabamos de usted para las siguientes finalidades que son necesarias para el servicio que solicita:',
          list: [
            {
              type: 'paragraph',
              text: 'Responder a las solicitudes de información sobre productos.',
            },
            {
              type: 'paragraph',
              text: 'Informar sobre actualizaciones de nuestros productos.',
            },
            {
              type: 'paragraph',
              text: 'Conocer el rendimiento de nuestro servicio.',
            },
            {
              type: 'paragraph',
              text: 'Para procesar los pagos, podemos utilizar la información que los Usuarios proporcionan sobre sí mismos al realizar un pedido solo para brindar servicio a ese pedido.',
            },
            {
              type: 'paragraph',
              text: 'Para enviar correos electrónicos periódicos, podemos usar la dirección de correo electrónico para enviar información del usuario y actualizaciones relacionadas con su pedido. También podrá ser utilizado para responder a sus consultas, preguntas y/u otras solicitudes.',
            },
          ],
        },
        {
          type: 'paragraph',
          text: 'Adicionalmente, utilizaremos su información personal para las siguientes finalidades que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle un mejor servicio. Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales: nombre completo, correo electrónico, fecha de nacimiento, ciudad de origen y número de teléfono.',
        },
        {
          type: 'paragraph',
          text: 'También existe información sobre el hardware y software de su ordenador o dispositivo personal, que TS INDUSTRIAL recopila automáticamente. Esta información puede incluir: su dirección IP, tipo de navegador de Internet utilizado, nombres de dominio, tiempos de acceso y direcciones de sitios web que lo remiten. Esta información es utilizada por TS INDUSTRIAL para la operación del servicio, para mantener la calidad del servicio prestado y para brindar estadísticas generales acerca del uso del sitio www.tsindustrial.com.',
        },
        {
          type: 'paragraph',
          text: 'TS INDUSTRIAL no tiene que vender, alquilar o ceder sus listas de clientes a terceros.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Registro al sitio:',
        },
        {
          type: 'paragraph',
          text: 'A los usuarios que elijan registrarse en nuestros sitios se les pedirá que proporcionen información de contacto (como nombre y dirección de correo electrónico). Usamos esta información, por ejemplo, para comunicarnos con el usuario sobre los servicios en nuestros sitios por los que ha expresado interés. Es generalmente es opcional que el usuario proporcione información demográfica (como el nivel de ingresos y el género) e identificadores únicos (como el nombre de usuario y la contraseña), pero se recomienda para que podamos brindar una experiencia más personalizada en nuestros sitios.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Cómo protegemos su información:',
        },
        {
          type: 'paragraph',
          text: 'Adoptamos prácticas adecuadas de recopilación, almacenamiento y procesamiento de datos y medidas de seguridad para proteger su información personal del acceso, uso o divulgación no autorizados. TS INDUSTRIAL mantiene la información de identificación personal que usted ha proporcionado, en servidores ubicados en un entorno controlado y seguro, protegido del acceso, uso o divulgación no autorizados.',
        },
        {
          type: 'paragraph',
          text: 'El intercambio de datos confidenciales y privados entre el Sitio y los Usuarios se realiza a través de un canal de comunicación seguro SSL encriptado y protegido con firmas digitales.',
        },
      ],
    },
  ],
};

export const ourPolicyEn = {
  title: 'Checkout Politics',
  descriptions: [],
  sections: [
    {
      id: uuid(),
      anchorId: ourPolicyAnchor.shippingAndFreightPolicy,
      title: 'TSI Parts Shipping and Freight Policy',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Note: All of the following information is strictly for ground orders.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Shipping Rates in the USA:',
      descriptions: [
        {
          type: 'list',
          text: 'For orders placed within the contiguous United States, the shipping rates are as follows:',
          list: [
            {
              type: 'paragraph',
              text: 'Note: Orders between $650 and $2499.99 qualify for free ground shipping if the customer selects ground transport. Orders over $2500 will be subject to paid freight charges.',
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Rules for Heavy Items:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Note: Any order containing a single item over 75 lbs or a combined order weight exceeding 300 lbs will be shipped via Less Than Truckload (LTL) freight, and shipping charges will apply.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Exclusion Items (Heavy Products):',
      descriptions: [
        {
          type: 'columns',
          text: 'The following items are excluded from free shipping and have special freight policies:',
          columns: [
            {
              type: 'row',
              text: 'Cart Value',
              row: [
                {
                  type: 'paragraph',
                  text: '$0.00 to $24.99',
                },
                {
                  type: 'paragraph',
                  text: '$25.00 to $149.99',
                },
                {
                  type: 'paragraph',
                  text: '$150.00 to $299.99',
                },
                {
                  type: 'paragraph',
                  text: '$300.00 to $499.99',
                },
                {
                  type: 'paragraph',
                  text: '$500.00 to $649.99',
                },
                {
                  type: 'paragraph',
                  text: '$650.00 to $2499.99',
                },
                {
                  type: 'paragraph',
                  text: '$2500+',
                },
              ],
            },
            {
              type: 'row',
              text: 'Freight',
              row: [
                {
                  type: 'paragraph',
                  text: '$16.00',
                },
                {
                  type: 'paragraph',
                  text: '$23.00',
                },
                {
                  type: 'paragraph',
                  text: '$28.00',
                },
                {
                  type: 'paragraph',
                  text: '$33.00',
                },
                {
                  type: 'paragraph',
                  text: '$37.00',
                },
                {
                  type: 'paragraph',
                  text: 'Free*',
                },
                {
                  type: 'paragraph',
                  text: 'Paid*',
                },
              ],
            },
          ],
        },
        {
          type: 'columns',
          columns: [
            {
              type: 'row',
              text: 'Weight',
              row: [
                {
                  type: 'paragraph',
                  text: 'Over 75 lbs',
                },
                {
                  type: 'paragraph',
                  text: 'Over 300 lbs',
                },
              ],
            },
            {
              type: 'row',
              text: 'Freight',
              row: [
                {
                  type: 'paragraph',
                  text: 'Paid LTL freight',
                },
                {
                  type: 'paragraph',
                  text: 'Paid LTL freight',
                },
              ],
            },
          ],
        },
        {
          type: 'paragraph',
          text: 'Items such as forks (including fork extensions) are subject to additional policies.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Custom Freight Quote Process:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'For heavy or bulky items, a custom freight quote will be requested during checkout. Once the request is received, the TSI Parts sales support team will contact the customer with an LTL freight quote. The order will then be updated, and the customer will receive an email with the final shipping cost to proceed with their purchase.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Shipping Rates in MEXICO:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'For orders placed within the contiguous Mexican Republic, the shipping rates are as follows:',
        },
        {
          type: 'paragraph',
          text: 'Note: All orders destined within the Mexican Republic that are $1,000.00 USD or more will be dispatched and invoiced with prepaid service, using the parcel chosen by the supplier (non-cumulative orders). Prepaid orders apply only to in-stock products and do not include the following items:',
        },
      ],
    },
    {
      id: uuid(),
      title: 'Exclusion Items (Special Material):',
      descriptions: [
        {
          type: 'list',
          text: 'Orders including the following material will be charged the rate decided by the freight company regardless of the value of the order:',
          list: [
            {
              type: 'paragraph',
              text: 'Engines',
            },
            {
              type: 'paragraph',
              text: 'Individual tires (75 lbs or more)',
            },
            {
              type: 'paragraph',
              text: 'Individual wheels (75 lbs or more)',
            },
            {
              type: 'paragraph',
              text: 'Pallet trucks',
            },
            {
              type: 'paragraph',
              text: 'Individual chargers (75 lbs or more)',
            },
            {
              type: 'paragraph',
              text: 'Individual accessories and load backrests (75 lbs or more)',
            },
            {
              type: 'paragraph',
              text: 'LPG tanks',
            },
            {
              type: 'paragraph',
              text: 'Side shifters',
            },
          ],
        },
        {
          type: 'columns',
          columns: [
            {
              type: 'row',
              text: 'Cart Value',
              row: [
                {
                  type: 'paragraph',
                  text: '$0.00 to $999.99',
                },
                {
                  type: 'paragraph',
                  text: '$1000.00+',
                },
              ],
            },
            {
              type: 'row',
              text: 'Freight',
              row: [
                {
                  type: 'paragraph',
                  text: '$15.00',
                },
                {
                  type: 'paragraph',
                  text: 'Free*',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'Back Orders:',
      descriptions: [
        {
          type: 'paragraph',
          text: 'Pending parts (backorders) will be consolidated with other items that are available in our warehouse unless you notify us otherwise.',
        },
      ],
    },
    {
      id: uuid(),
      title: 'RETURN AND CANCELLATION POLICY',
      descriptions: [
        {
          anchorId: ourPolicyAnchor.cancellations,
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Cancellations:',
        },
        {
          type: 'paragraph',
          text: 'Orders cannot be canceled after they have been processed.',
        },
        {
          anchorId: ourPolicyAnchor.generalReturns,
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'General Returns:',
        },
        {
          type: 'paragraph',
          text: 'All returns must be requested within 30 days from the invoice date. ONLY merchandise with a Return Authorization (RA) number will be accepted for return. Orders for special products such as Paints, Lubricants, LPG Tanks, Chemicals, and Fire Extinguishers are non-returnable and non-cancelable. Parts must be returned to TSIndustrial prepaid by the sender.',
        },
        {
          type: 'paragraph',
          text: 'All returns (except those due to product defect or incorrect part) will be subject to a 25% restocking fee.',
        },
        {
          type: 'paragraph',
          text: 'Products returned for defects will be inspected and tested. All merchandise verified as defective, at TSIndustrial’s sole discretion, will be repaired or replaced at no charge, or a credit note will be issued for the value, including freight. Returned parts deemed functional will be subject to a 25% restocking fee.',
        },
        {
          type: 'paragraph',
          text: 'No credit will be issued for merchandise damaged by corrosive materials, abuse, alteration, or misuse.',
        },
        {
          type: 'numeric',
          text: 'Before returning any merchandise to TSIndustrial, your sales representative or customer service department must generate a "Return Authorization Number." Your request must include:',
          numeric: [
            {
              type: 'paragraph',
              text: 'Part number and description',
            },
            {
              type: 'paragraph',
              text: 'Reason for return',
            },
            {
              type: 'paragraph',
              text: 'TSIndustrial invoice number',
            },
            {
              type: 'paragraph',
              text: 'Customer order number',
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      title: 'PRIVACY POLICY',
      descriptions: [
        {
          type: 'paragraph',
          text: 'TS INDUSTRIAL is committed to protecting your online privacy while providing you with the most useful and enjoyable web experience possible. TS INDUSTRIAL is dedicated to safeguarding your privacy and developing technology that delivers the most powerful and secure online experience. This Privacy Statement applies to the website www.tsindustrial.com and governs data collection and usage.',
        },
        {
          type: 'paragraph',
          text: 'By using this website, you consent to the data practices described in this statement. Users can access many features of our site anonymously; however, you may be asked to provide personal information when participating in contests, surveys, or providing feedback, or when using other interactive areas of our site. Use of our site implies your understanding and acceptance of the terms of this privacy policy.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'How we collect and use personal information:',
        },
        {
          type: 'paragraph',
          text: 'We collect information from our users at various points on our site. Generally, each user has access to all the information and functions available on our site. However, users who choose to be notified about product availability will be required to provide specific user information.',
        },
        {
          type: 'list',
          text: 'We will use the personal data we collect from you for the following purposes necessary for the service you request:',
          list: [
            {
              type: 'paragraph',
              text: 'Respond to product information requests',
            },
            {
              type: 'paragraph',
              text: 'Notify you of product updates',
            },
            {
              type: 'paragraph',
              text: 'Understand the performance of our service',
            },
            {
              type: 'paragraph',
              text: 'Process payments for orders',
            },
            {
              type: 'paragraph',
              text: 'We may use your email address to send periodic emails containing order-related information and updates, respond to inquiries, questions, and/or other requests.',
            },
          ],
        },
        {
          type: 'paragraph',
          text: 'Additionally, we will use your personal information for purposes that are not necessary for the requested service but help us provide you with better service, such as sending promotional content or surveys.',
        },
        {
          type: 'paragraph',
          text: 'There is also information about your computer hardware and software that TS INDUSTRIAL collects automatically. This information can include: your IP address, browser type, domain names, access times, and referring website addresses. This information is used by TS INDUSTRIAL for the operation of the service, to maintain the quality of the service provided, and to provide general statistics regarding the use of the site www.tsindustrial.com.',
        },
        {
          type: 'paragraph',
          text: 'TS INDUSTRIAL does not sell, rent, or lease its customer lists to third parties.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'Site Registration:',
        },
        {
          type: 'paragraph',
          text: 'Users who choose to register on our sites will be asked to provide contact information (such as name and email address). We use this information, for example, to communicate with the user about the services on our sites in which they have expressed interest. Providing demographic information (such as income level and gender) and unique identifiers (such as username and password) is generally optional but is recommended so we can provide a more personalized experience on our sites.',
        },
        {
          underline: true,
          italic: true,
          type: 'paragraph',
          text: 'How We Protect Your Information:',
        },
        {
          type: 'paragraph',
          text: 'We adopt appropriate data collection, storage, and processing practices, as well as security measures, to protect your personal information from unauthorized access, use, or disclosure. TS INDUSTRIAL keeps the personal identification information you have provided on servers located in a controlled and secure environment, protected from unauthorized access, use, or disclosure.',
        },
        {
          type: 'paragraph',
          text: 'The exchange of sensitive and private data between the Site and Users is conducted through an SSL-encrypted secure communication channel and is protected with digital signatures.',
        },
      ],
    },
  ],
};