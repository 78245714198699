import React from 'react';
import uuid from 'react-uuid';
import CurrencyFormat from 'react-currency-format';
import Image from 'react-bootstrap/Image';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Accordion, AccordionSummary, AccordionDetails, IconButton, Divider } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';

import ProductQtyInput from '../../../../Components/Generals/Figma/Molecules/ProductQtyInput';
import Font from '../../../Generals/Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import CurrencyFont from '../../../../Components/Generals/Fonts/CurrencyFont';
import { useStyles } from './OrderSummaryCard.styles';
import {
  selectSubTotal,
  selectFreight,
  selectTotal,
  selectTaxes,
  selectProducts,
  deleteProduct,
  AddQuantity,
  SubQuantity,
  AddQuantityInput,
} from '../../../../Redux/Slices/ShoppingCart.Slices';
import * as colors from '../../../../assets/GlobalColors';
import { ourPolicyAnchor } from '../../../../Screens/Client/LandingPage/PageData/PageData';

const ProductItem = ({ classes, product, isSummary }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  const [productCategories, setProductCategories] = React.useState('');
  const [productCodes, setProductCodes] = React.useState('');
  const [productName, setProductName] = React.useState('');

  const handleAddQuantityInput = (value) => {
    dispatch(
      AddQuantityInput({
        _id: product._id,
        quantity: value,
      })
    );
  };

  const handleClickSubQuantity = (e) => {
    e.preventDefault();

    dispatch(
      SubQuantity({
        _id: product._id,
      })
    );
  };

  const handleClickAddQuantity = (e) => {
    e.preventDefault();
    dispatch(
      AddQuantity({
        _id: product._id,
      })
    );
  };

  const handleClickDeleteProduct = (e) => {
    e.preventDefault();

    dispatch(
      deleteProduct({
        _id: product._id,
      })
    );
  };

  React.useEffect(() => {
    setProductName(product.name);

    if (product.categoryName) {
      setProductCategories(`${product.categoryName} / ${product.subCategory} / ${product.label}`);
    }

    if (Array.isArray(product.codes)) {
      product.codes.map((code) => {
        setProductCodes((prevCodes) => `${prevCodes} ${code}`);
      });
    } else setProductCodes(product.codes);
  }, [product]);

  return (
    <Box component="div" className={classes.productItem}>
      <Box
        component="div"
        className="rowBox"
        style={{
          width: 'auto',
          height: '100%',
        }}
      >
        <Image src={product.image} alt="product" className={classes.image} />

        <Box
          component="div"
          style={{
            width: '300px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '20px',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Font text={productName} color={colors.BLACK} size={14} lineHeight="24px" isHead={false} />

            <Box
              component="div"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Font text={`Precio unitario`} color={colors.BLACK_06} size={14} lineHeight="24px" isHead={false} />

              <CurrencyFormat
                value={product.price}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                prefix={'$'}
                renderText={(value) => (
                  <Font text={value} color={colors.BLACK_06} size={14} lineHeight="24px" isHead={false} />
                )}
              />
            </Box>

            <Box
              component="div"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <Font text={productCategories} color={colors.BLACK_06} size={14} lineHeight="24px" isHead={false} />

              <Font text={productCodes} color={colors.BLACK_06} size={14} lineHeight="24px" isHead={false} />
            </Box>
          </Box>

          <Box
            component="div"
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '10px',
            }}
          >
            <Font text={t('Generals.quantity')} color={colors.BLACK_06} size={14} lineHeight="24px" isHead={false} />

            {isSummary === false ? (
              <IconButton
                onClick={handleClickSubQuantity}
                style={{
                  width: '25px',
                  height: '25px',
                }}
              >
                <RemoveIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    color: '#000',
                  }}
                />
              </IconButton>
            ) : null}

            {isSummary === false ? (
              <ProductQtyInput
                qty={product.productQuantity || product.quantitySelected}
                handleAddQuantityInput={handleAddQuantityInput}
              />
            ) : (
              <Font
                text={product.productQuantity || product.quantitySelected}
                color={colors.BLACK_06}
                size={14}
                lineHeight="24px"
                isHead={false}
              />
            )}

            {isSummary === false ? (
              <IconButton
                onClick={handleClickAddQuantity}
                style={{
                  width: '25px',
                  height: '25px',
                }}
              >
                <AddIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    color: '#000',
                  }}
                />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        style={{
          width: 'auto',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <CurrencyFont
          price={product.quantityPrice}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size14.size}
          lineHeight={generalsEnums.fontSizes.size14.lineHeight}
          isHead={true}
        />

        {isSummary === false ? (
          <IconButton onClick={handleClickDeleteProduct}>
            <DeleteIcon className={classes.DeleteIcon} />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};

const OrderSummaryCard = ({
  isSummary,
  handlePrintOrderTicket,
  productsOrder,
  orderSubTotal,
  orderDelivery,
  orderTaxes,
  orderTotal,
  canEdit,
  handleChangeIsSummary,
  orderSummary,
  w,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation('global');

  const [products, setProducts] = React.useState([]);
  const [isBought, setIsBought] = React.useState(false);
  const [cardSubTotal, setCardSubTotal] = React.useState();
  const [cardTaxes, setCardTaxes] = React.useState();
  const [cardFreight, setCardFreight] = React.useState();
  const [cardTotal, setCardTotal] = React.useState();

  const subTotal = useSelector(selectSubTotal);
  const total = useSelector(selectTotal);
  const taxes = useSelector(selectTaxes);
  const productsCart = useSelector(selectProducts);
  const freight = useSelector(selectFreight);

  const rows = [
    {
      name: t('Generals.subtotal'),
      price: cardSubTotal,
    },
    {
      name: t('Generals.taxes'),
      price: cardTaxes,
    },
    {
      name: t('Generals.delivery'),
      price: cardFreight,
    },
  ];

  const handleDelivery = (e) => {
    e.preventDefault();
    navigate(`/information/our-policy/${ourPolicyAnchor.shippingAndFreightPolicy}`);
  };

  const handleCancelationPolicy = (e) => {
    e.preventDefault();
    navigate(`/information/our-policy/${ourPolicyAnchor.cancellations}`);
  };

  const handleReturnsPolicy = (e) => {
    e.preventDefault();
    navigate(`/information/our-policy/${ourPolicyAnchor.generalReturns}`);
  };

  React.useEffect(() => {
    if (isSummary) {
      setProducts(productsOrder ? productsOrder : productsCart);
    } else {
      setProducts(productsCart);
    }
  }, [isSummary, productsOrder, productsCart]);

  React.useEffect(() => {
    if (location.pathname.includes('/order-summary/')) {
      setIsBought(true);
    }
  }, [location]);

  const updateState = (orderValue, value, setStateFunction) => {
    if (orderValue !== undefined) {
      const orderValueFloat = parseFloat(orderValue);
      setStateFunction(orderValueFloat);
    } else if (value !== null) {
      setStateFunction(value);
    } else {
      setStateFunction(null);
    }
  };

  React.useEffect(() => {
    updateState(orderSubTotal, subTotal, setCardSubTotal);
    updateState(orderTaxes, taxes, setCardTaxes);
    updateState(orderTotal, total, setCardTotal);
  }, [orderSubTotal, subTotal, orderTaxes, taxes, orderTotal, total]);

  React.useEffect(() => {
    if (orderDelivery !== undefined) {
      if (orderDelivery !== null) {
        const orderDeliveryFloat = parseFloat(orderDelivery);
        setCardFreight(orderDeliveryFloat);
      } else {
        setCardFreight(t('OrderSummaryCard.freightIsString'));
      }
    } else {
      if (freight !== null) {
        setCardFreight(typeof freight === 'number' ? freight : t('OrderSummaryCard.freightIsString'));
      } else {
        setCardFreight(null);
      }
    }
  }, [orderDelivery, freight, t]);

  return (
    <Box
      component="div"
      className={classes.root}
      sx={{
        width: w ? w : '550px',
      }}
    >
      <Box component="div" className={classes.element}>
        <Box component="div" className={classes.header}>
          <Font
            text={orderSummary ? t('client.OrderSummary.summaryTitle') : t('client.CartModal.title')}
            color="#000000"
            size={16}
            lineHeight="26px"
            isHead={true}
          />

          {canEdit ? (
            isSummary ? (
              <Button onClick={handleChangeIsSummary} variant="contained" className={classes.editButton}>
                <EditIcon className={classes.CloseIcon} />

                <Font text={t('Generals.edit')} color="#000000" size={14} lineHeight="22px" isHead={false} />
              </Button>
            ) : null
          ) : null}
        </Box>

        <Box component="div" className={classes.body}>
          {products.map((product, idx) => {
            return (
              <ProductItem classes={classes} key={idx} product={product} isSummary={isSummary} isBought={isBought} />
            );
          })}
        </Box>
      </Box>

      <Box component="div" className={classes.element}>
        {orderSummary ? (
          <Box component="div" className={classes.emptyBox} />
        ) : (
          <Box
            component="div"
            className={classes.header}
            style={{
              borderTop: `2px solid rgba(204, 204, 204, 1)`,
            }}
          >
            <Accordion className={classes.Accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.CloseIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Font
                  text={t('client.CartModal.our-policy')}
                  color="#000000"
                  size={14}
                  lineHeight="22px"
                  isHead={true}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Box component="div" className={classes.policies}>
                  <Button onClick={handleDelivery} variant="text" className={classes.textButton}>
                    <Font text={t('Generals.delivery')} color="#000000" size={14} lineHeight="22px" isHead={false} />
                  </Button>

                  <Button onClick={handleCancelationPolicy} variant="text" className={classes.textButton}>
                    <Font text={t('Generals.cancel')} color="#000000" size={14} lineHeight="22px" isHead={false} />
                  </Button>

                  <Button onClick={handleReturnsPolicy} variant="text" className={classes.textButton}>
                    <Font text={t('Generals.back')} color="#000000" size={14} lineHeight="22px" isHead={false} />
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}

        {orderSummary ? (
          <Divider
            sx={{
              width: '100%',
              backgroundColor: 'rgba(204, 204, 204, 1)',
            }}
          />
        ) : null}

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100%',
            padding: '8px',
          }}
        >
          {rows.map((row) => {
            const { name, price } = row;
            const displayPrice = price === null ? false : true;
            return (
              <Box
                key={uuid()}
                component={generalsEnums.BoxDiv}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginBottom: '10px',
                  gap: '12px',
                }}
              >
                <Font text={name} color="#000000" size={14} lineHeight="22px" isHead={true} />

                {displayPrice ? (
                  <>
                    {typeof price === 'string' ? (
                      <Font text={price} color="#000000" size={14} lineHeight="22px" isHead={false} />
                    ) : (
                      <CurrencyFont
                        price={price}
                        color={colors.BLACK}
                        size={generalsEnums.fontSizes.size14.size}
                        lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                        isHead={generalsEnums.falseBoolean}
                        displayPrefix={generalsEnums.trueBoolean}
                      />
                    )}
                  </>
                ) : null}
              </Box>
            );
          })}
        </Box>

        {orderSummary ? (
          <Divider
            sx={{
              width: '100%',
              backgroundColor: 'rgba(204, 204, 204, 1)',
            }}
          />
        ) : null}

        <Box
          component="div"
          sx={{
            width: '100%',
            padding: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: orderSummary ? '20px' : '0px',
          }}
        >
          <Font text={'Total'} color="#000000" size={16} lineHeight="26px" isHead={true} />

          <CurrencyFont
            price={cardTotal}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size20.size}
            lineHeight={generalsEnums.fontSizes.size20.lineHeight}
            isHead={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSummaryCard;
